import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Modal, { useModal } from '../../../components/Modal/Modal'
import { Survivor } from '../../../types'
import formatIpfsUrl from '../../../utils/formatIpfsUrl'
import { ActiveFilter, PAGE_SIZE } from '../TheSurvivors'
import * as Styled from '../TheSurvivors.styles'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import placeholderImg from '../../../components/media/placeholder-rec.png'

interface ListProps {
	data: Survivor[]
	page: number
	activeFilter: ActiveFilter
}

interface SurvivorProps {
	name: string
	id: number
	image: string
	backstory: string
}

export const ImageWithPlaceholder = ({ src = '', alt = '', placeholder = '' }) => {
	const [imgSrc, setImgSrc] = useState(placeholder)

	useEffect(() => {
		const img = new Image()
		img.src = src
		img.onload = () => {
			setImgSrc(src)
		}
	}, [src])
	return <img src={imgSrc} alt={alt} />
}

const SurvivorPreview = ({ id, image, name, backstory }: SurvivorProps) => {
	const { toggle, isShowing } = useModal()

	return (
		<Col xs={6} sm={4} md={3} lg={2} className="pb-5">
			<Styled.ItemImage onClick={toggle}>
				<ImageWithPlaceholder src={formatIpfsUrl(image)} placeholder={placeholderImg} />
				<Styled.ItemImageOverlay className="item-image-overlay">
					Read
					<br />
					Background
					<br />
					Story
				</Styled.ItemImageOverlay>
			</Styled.ItemImage>
			<div className="d-flex justify-content-between">
				<Styled.ItemTitle>{name}</Styled.ItemTitle>
				<Styled.ItemSubTitle>#{id}</Styled.ItemSubTitle>
			</div>
			<Modal
				toggle={toggle}
				isShowing={isShowing}
				content={{ title: name, text: backstory, subTitle: `#${id}`, image }}
			/>
		</Col>
	)
}

const SurvivorNameGroup = ({ data }: { data: Survivor[] }) => {
	const [hidden, setHidden] = React.useState(false)

	const toggleHidden = () => {
		setHidden(!hidden)
	}

	return (
		<div>
			<Styled.DropdownButton onClick={toggleHidden}>
				<span style={{ marginRight: '5px' }}> {data[0].name}</span>
				{hidden ? <BsChevronDown /> : <BsChevronUp />}
			</Styled.DropdownButton>

			{!hidden && (
				<Row>
					{data.map(({ id, name, image, backstory }) => (
						<SurvivorPreview key={id} id={id} name={name} image={image} backstory={backstory} />
					))}
				</Row>
			)}
		</div>
	)
}

export const SurvivorsList: React.FC<ListProps> = ({ data = [], page, activeFilter }) => {
	const groupedByName = useMemo(
		() =>
			data.slice(0 * PAGE_SIZE, page * PAGE_SIZE).reduce((acc, el) => {
				if (!acc[el.name]) {
					acc[el.name] = []
				}
				acc[el.name].push(el)
				return acc
			}, {} as any),
		[data, page],
	)

	if (activeFilter === 'owned' && !data.length)
		return <Styled.Paragraph>You don't own any survivors yet</Styled.Paragraph>

	return (
		<>
			{Object.keys(groupedByName).map((name) => (
				<SurvivorNameGroup key={name} data={groupedByName[name]} />
			))}
		</>
	)
}
