import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useMetamask } from './useMetamask'
import { useRpc } from './useRpc'
import {
	TheSevens,
	TheSevens__factory,
	TheSevensDojo,
	TheSevensDojo__factory,
	ZeniToken,
	ZeniToken__factory,
} from '../contracts'

interface Contracts {
	theSevenContract: TheSevens
	theSevenDojoContract: TheSevensDojo
	ZeniTokenContract: ZeniToken
}

export interface IContext {
	contracts?: Contracts
}

export function useContract(): IContext {
	return useContext(ContractContext)
}

export const ContractContext = React.createContext<IContext>({
	contracts: undefined,
})

const TheSevensAddress = "0xf497253C2bB7644ebb99e4d9ECC104aE7a79187A"
const TheSevensDojoAddress = "0xD4f8B4826847CA01652ACaaF09e48b3cB96f72DF"
const ZeniTokenAddress = "0x2E59D147962E2bB3fBdc52dc18CfBa2653C06Ccc"

export const ContractProvider: React.FunctionComponent = ({ children }) => {
	const [contracts, setContract] = useState<Contracts | undefined>(undefined)
	const { metamask } = useMetamask()
	const { provider: rpcProvider } = useRpc()

	const initContract = useCallback(() => {
		if (TheSevensAddress !== undefined && TheSevensDojoAddress !== undefined && ZeniTokenAddress !== undefined) {
			if (metamask !== undefined) {
				const signer = metamask.getSigner()
				const theSevenContract = TheSevens__factory.connect(TheSevensAddress, signer)
				const theSevenDojoContract = TheSevensDojo__factory.connect(TheSevensDojoAddress, signer)
				const ZeniTokenContract = ZeniToken__factory.connect(ZeniTokenAddress, signer)

				setContract({
					theSevenContract,
					theSevenDojoContract,
					ZeniTokenContract,
				})
			} else if (rpcProvider !== undefined) {
				const theSevenContract = TheSevens__factory.connect(TheSevensAddress, rpcProvider)
				const theSevenDojoContract = TheSevensDojo__factory.connect(TheSevensDojoAddress, rpcProvider)
				const ZeniTokenContract = ZeniToken__factory.connect(TheSevensDojoAddress, rpcProvider)
				setContract({
					theSevenContract,
					theSevenDojoContract,
					ZeniTokenContract,
				})
			}
		}
	}, [metamask, rpcProvider])

	useEffect(() => {
		initContract()
	}, [initContract])

	return (
		<ContractContext.Provider
			value={{
				contracts,
			}}
		>
			{children}
		</ContractContext.Provider>
	)
}
