import styled from 'styled-components'

interface ButtonProps {
	children: any
	circle: string | boolean
}

const Button = (props: ButtonProps) => {
	const circle = props.circle
	const children = props.children
	if (circle) {
		return (
			<BtnFilter>
				<Circle style={{ backgroundColor: `${circle}` }} />
				{children}
			</BtnFilter>
		)
	}

	return (
		<BtnContainer>
			<Btn>{children}</Btn>
		</BtnContainer>
	)
}

/* PRIMARY BUTTON */
const BtnContainer = styled.div`
	display: inline-block;
	font-size: 14px;
	font-family: 'RobotoRegular', sans-serif;
	@media (max-width: 992px) {
		display: flex;
		justify-content: center;
	}
`

const Btn = styled.button`
	padding: 8px 24px;
	border-radius: 4px;
	border: none;
	background-color: rgba(0, 0, 0, 1);
	color: white;
	transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}
`

/* FILTER BUTTON */

const BtnFilter = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: 'RobotoRegular', sans-serif;
	font-weight: 600;
	padding: 8px 24px;
	border-radius: 4px;
	background-color: white;
	border: 1px solid #e0e0e0;
	transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover,
	&:focus {
		background-color: black;
		color: white;
	}
`

const Circle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-right: 12px;
`

export default Button
