import { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ThemeProvider } from '../hooks/useTheme'
import { Container404 } from './Container404'
import Nav from '../components/nav/Nav'
import CustomToastContainer from '../components/Toast/Toast'
import { StyledLink } from '../components/theme/Texts'
import ReactGA from 'react-ga'
import 'react-toastify/dist/ReactToastify.css'
import newDojo from './Dojo'
import { useLoading } from '../hooks/useLoading'
import { LoadingMask } from '../components/LoadingContent/LoadingContent'
import NewDiscordAuth from './DiscordAuth/DiscordAuth.js'
import LPPage from './LPPage'
import oldDojo from './DojoOld'
import HomeEve from './HomeEve'
import TheSurvivors from './TheSurvivors'
import TheCluster from './TheCluster'
import Manifesto from './Manifesto'
// import EveMint from "./EveMint";
// import DiscordAuth from './OldDiscordAuth';
// import WhitelistCheck from './MintNew/WhitelistCheck';
// import MintNew from "./MintNew";
import 'bootstrap/dist/css/bootstrap.min.css'
import ScavengerHunt from './ScavengerHunt'
import Revamp from './Revamp'

function usePageViews() {
	let location = useLocation()
	useEffect(() => {
		ReactGA.initialize('G-CZC2PDJMBR')
		ReactGA.set({ page: location.pathname })
		//to report page view
		ReactGA.pageview(location.pathname)
	}, [location])
}

const Page404 = () => (
	<Container404>
		<p className="text-white">Sorry, that page does not exist!</p>
		<StyledLink to="/">Go to Home</StyledLink>
	</Container404>
)

export default function App() {
	//UNCOMMENT LATER FOR GOOGLE ANALYTICS
	usePageViews()
	const { isLoading } = useLoading()
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return (
		<ThemeProvider>
			<Nav />
			<CustomToastContainer />
			{isLoading && <LoadingMask />}
			<Switch>
				<Route path="/discord-auth" component={NewDiscordAuth} />
				<Route path="/dojo" component={newDojo} />
				<Route path="/old-dojo" component={oldDojo} />
				<Route path="/liquidity-pool" component={LPPage} />
				<Route path="/manifesto" component={Manifesto} />
				{/* <Route path="/the-sevens-eve" component={EveMint} /> */}
				<Route path="/the-survivors" component={TheSurvivors} />
				<Route path="/revamp" component={Revamp} />
				<Route path="/scavenger-hunt" component={ScavengerHunt} />
				<Route path="/the-cluster" component={TheCluster} />
				<Redirect to="/discord-auth" from="/discordAuth" />
				<Route exact path="/" component={HomeEve} />
				<Route component={Page404} />
				{/*<Route path="/old-discord-auth" component={DiscordAuth} />*/}
				{/*<Route path="/mint" component={MintNew} />*/}
				{/* <Route path="/whitelist-check" component={WhitelistCheck} /> */}
			</Switch>
		</ThemeProvider>
	)
}
