import { FormControl } from 'react-bootstrap'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/all'

export const InputWrapper = styled.div`
	position: relative;
	margin-top: 8px;
	margin-bottom: 32px;
	color: #fff;
`

export const SearchIcon = styled(FaSearch)`
	position: absolute;
	top: 50%;
	left: 24px;
	transform: translateY(-50%);
	font-size: 16px;
`

export const Input = styled(FormControl)`
	height: initial;
	padding: 8px 8px 8px 59px;
	border: 0;
	border-radius: 2px;
	font-family: 'RobotoMono', monospace;
	font-size: 16px;
	line-height: 21px;

	&::placeholder {
		color: #ddd;
	}

	&,
	&:focus {
		background-color: #393939;
		color: inherit;
	}
`
