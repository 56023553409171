import React from 'react'

export interface StakingBoxProps {
	title: string
	subTitle?: string
	buttonText?: string
	buttonFunction?: () => any
	buttonDisable: boolean
	content?: string | JSX.Element
}

function StakingBox(props: StakingBoxProps) {
	return (
		<div className="border-black border-2 align-center items-center text-center p-24 mobile:p-0 tablet:p-4">
			<div className="w-full text-24px font-bold mobile:text-sm mobile:mt-20">
				<p>{props.title}</p>
			</div>
			{props.subTitle && (
				<div className="text-lg m-auto word-md font-bold mobile:text-32px mobile:pb-14 tablet:w-4/5">
					<p>{props.subTitle}</p>
				</div>
			)}
			{props.buttonText && (
				<button
					className={`${
						props.buttonDisable ? 'opacity-50' : ''
					} bg-black w-1/2 m-auto mt-4 p-2 text-sm text-white cursor-pointer mobile:hidden`}
					onClick={props.buttonFunction}
				>
					{props.buttonText}
				</button>
			)}
			{props.content}
			{props.buttonText && (
				<button
					className="hidden bg-black w-full h-16 m-auto mt-4 text-sm text-white cursor-pointer mobile:block py-5 hover"
					onClick={props.buttonFunction}
					type="button"
				>
					{props.buttonText}
				</button>
			)}
		</div>
	)
}

export default React.memo(StakingBox)
