import styled from 'styled-components'

const Container = styled.div`
	width: 100%;
	max-width: 1370px;
	margin-left: auto;
	margin-right: auto;
	padding-right: 15px;
	padding-left: 15px;
`

export default Container
