import styled from 'styled-components'

export const Section = styled.section`
	display: flex;
	justify-content: center;
	flex-direction: column;
	min-height: 80vh;
	padding-top: 120px;
	padding-bottom: 120px;
`

export const Title = styled.h1`
	font-family: 'AkiraExpanded', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 80px;
	line-height: 88px;
	color: #fff;
	text-transform: uppercase;

	@media (max-width: 992px) {
		font-size: 48px;
		line-height: 56px;
	}
`

export const Paragraph = styled.p`
	font-family: 'Roboto Mono', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #fff;

	a,
	a:hover {
		color: #fff;
	}
`

export const Highlight = styled.span`
	position: relative;
	font-weight: 700;

	&:before {
		background-color: #eb1c24;
		content: '';
		position: absolute;
		width: 100%;
		height: 15px;
		z-index: -1;
		transform: rotate(-2deg);
		margin-top: 5px;
	}
`

export const Footer = styled.div`
	padding: 64px 0 64px 0;
	width: 100%;
	height: 100%;
	background-color: black;
	color: white;
`

export const ItemImage = styled.button`
	position: relative;
	display: block;
	width: 100%;
	appearance: none;
	overflow: hidden;
	padding-top: 100%;
	border-radius: 2px;
	border: 0;
	background-color: rgba(255, 255, 255, 0.01);
	transition: 0.2s ease;

	&:hover {
		box-shadow: 1px 4px 13px 2px rgba(0, 0, 0, 0.25);

		.item-image-overlay {
			opacity: 1;
			transform: none;
		}
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

export const MenuContainer = styled.div`
	display: flex;
	margin-top: 32px;
	margin-bottom: 72px;
	overflow: auto;
`

export const Menu = styled.nav`
	display: inline-grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;
	gap: 16px;
`

export const MenuItem = styled.button`
	min-width: 80px;
	padding: 2px 4px;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoMono', sans-serif;
	background-color: transparent;
	border-radius: 2px;
	border: 0;
	transition: 0.2s ease;
	text-decoration-color: #eb1c24;

	&:not(.disabled):not(.active):hover {
		color: #fff;
		text-decoration: underline;
		text-decoration-color: #eb1c24;
	}

	&.active {
		background-color: #eb1c24;
		color: #fff;
	}

	&.disabled {
		color: #999;
	}
`

export const ItemImageOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(28, 28, 28, 0.9);
	font-family: 'RobotoMono', monospace;
	font-weight: 600;
	color: #fff;
	opacity: 0;
	transition: 0.2s ease;
	transform: scale(1.1);

	&.selected {
		opacity: 1;
		transform: none;
	}
`

export const ItemTitle = styled.span`
	display: inline-block;
	font-family: 'RobotoMono', monospace;
	color: #fff;
`

export const ItemSubTitle = styled.span`
	display: inline-block;
	font-family: 'RobotoMono', monospace;
	color: #fff;
`

export const ModalBody = styled.div`
	flex-grow: 1;
	padding: 40px 32px;
	background-color: #000;
	color: #fff;
`

export const ModalTitle = styled.span`
	display: block;
	margin-bottom: 12px;
	font-weight: 800;
	font-size: 24px;
	line-height: 28px;
`

export const ModalSubTitle = styled.span`
	display: block;
	margin-bottom: 32px;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
`

export const ModalText = styled.p`
	display: block;
	margin-bottom: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
`

export const Close = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	width: 32px;
	height: 32px;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.3);
	color: #fff;
	border: 0;
	border-radius: 50%;
	line-height: 0;

	svg {
		width: 16px;
		height: 16px;
	}
`

export const Button = styled.button`
	width: 100%;
	max-width: 320px;
	padding: 12px 24px;
	margin-right: auto;
	margin-left: auto;
	border: 0;
	background-color: #eb1c24;
	color: #fff;
	font-family: 'RobotoMono', monospace;
	font-weight: 600;
	transition: 0.2s ease;

	&:hover {
		opacity: 0.7;
	}
`

export const FloatingDiv = styled.div`
	position: fixed;
	bottom: 0;
	left: 50%;
	margin-right: auto;
	margin-left: auto;
	transition: 0.2s ease;
	transform: translate(-50%, 100%);

	&.in {
		transform: translate(-50%, -100%);
	}
`

export const FloatingButton = styled.button`
	width: calc(100% - 30px);
	max-width: 320px;
	padding: 12px 24px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	border: 0;
	background-color: #eb1c24;
	color: #fff;
	font-family: 'RobotoMono', monospace;
	font-weight: 600;
	transition: 0.2s ease;

	&:hover {
		background-color: #bd151d;
	}
`
