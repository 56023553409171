export const MINTING_START_TIMESTAMP = 1640199600000

if (!"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161") {
	throw new Error('REACT_APP_ETH_JSON_RPC is empty')
}

export const ETH_JSON_RPC: string = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"

if (!1) {
	throw new Error('REACT_APP_FIRST_STAGE_MAXCOUNT is empty')
}

export const FIRST_STAGE_MAXCOUNT: string = "1"

if (!7) {
	throw new Error('REACT_APP_SECOND_STAGE_MAXCOUNT is empty')
}

export const SECOND_STAGE_MAXCOUNT: string = "7"

if (!7000) {
	throw new Error('REACT_TOTAL_SUPPLY is empty')
}
export const TOTAL_SUPPLY: string = "7000"

if (!"https://discord.com/api/oauth2/authorize?client_id=888075696872767519&redirect_uri=https%3A%2F%2Fapi-ts.treasure-hunt.thesevensofficial.com%2Fdiscord%2Foauth&response_type=code&scope=identify") {
	throw new Error('REACT_DISCORD_OAUTH is empty')
}
export const DISCORD_OAUTH: string = "https://discord.com/api/oauth2/authorize?client_id=888075696872767519&redirect_uri=https%3A%2F%2Fapi-ts.treasure-hunt.thesevensofficial.com%2Fdiscord%2Foauth&response_type=code&scope=identify"

if (!"https://api-ts.treasure-hunt.thesevensofficial.com/discord/user?session=") {
	throw new Error('REACT_DISCORD_USER_NAME_URL is empty')
}
export const DISCORD_USERNAME_URL: string = "https://api-ts.treasure-hunt.thesevensofficial.com/discord/user?session="

if (!"https://api-ts.treasure-hunt.thesevensofficial.com/puzzle/current") {
	throw new Error('PUZZLE_DATA_URL is empty')
}
export const PUZZLE_DATA_URL: string = "https://api-ts.treasure-hunt.thesevensofficial.com/puzzle/current"

if (!"https://api-ts.treasure-hunt.thesevensofficial.com/submit") {
	throw new Error('SUBMIT_ANSWER_URL is empty')
}
export const SUBMIT_ANSWER_URL: string = "https://api-ts.treasure-hunt.thesevensofficial.com/submit"

if (!"https://discord.com/api/oauth2/authorize?client_id=888075696872767519&redirect_uri=https%3A%2F%2Fapi.discord.thesevensofficial.com%2Fdiscord%2Foauth&response_type=code&scope=identify") {
	throw new Error('DISCORD_OAUTH_CONNECT_WALLET is empty')
}
export const DISCORD_OAUTH_CONNECT_WALLET: string = "https://discord.com/api/oauth2/authorize?client_id=888075696872767519&redirect_uri=https%3A%2F%2Fapi.discord.thesevensofficial.com%2Fdiscord%2Foauth&response_type=code&scope=identify"

if (!"https://api.discord.thesevensofficial.com/submit") {
	throw new Error('CONNECT_DISCORD_WITH_WALLET_URL is empty')
}
export const CONNECT_DISCORD_WITH_WALLET_URL: string = "https://api.discord.thesevensofficial.com/submit"

if (!"https://api.discord.thesevensofficial.com/discord/user?session=") {
	throw new Error('CONNECT_DISCORD_GET_DATA_URL is empty')
}
export const CONNECT_DISCORD_GET_DATA_URL: string = "https://api.discord.thesevensofficial.com/discord/user?session="

if (!"https://api.staking.thesevensofficial.com/") {
	throw new Error('STAKING_API is empty')
}
export const STAKING_API: string = "https://api.staking.thesevensofficial.com/"
