import styled from 'styled-components'
import Tooltip from '../misc/Tooltip'
import Button from '../buttons/Button'
import { AiOutlineTwitter, AiFillInstagram } from 'react-icons/ai'
import { FaDiscord } from 'react-icons/fa'

export default function Footer() {
	return (
		<SectionContainer>
			<ContentWrapper>
				<h1 className="title1">Join the Community</h1>
				<p className="text1 text-gray-dark">
					Have any questions about the project? Join the discord for more information.
				</p>
				<a href="https://discord.gg/the-sevens-gbtw" target="_blank" rel="noreferrer">
					<Button circle={false}>Join The Sevens Discord</Button>
				</a>
			</ContentWrapper>

			<SocialContainer>
				<FooterItem>
					<Tooltip text={['Twitter']} clickable={false}>
						<IconLink href="https://twitter.com/thesevens_7" target="_blank" rel="noreferrer">
							<AiOutlineTwitter style={{ width: '24px', height: '24px' }} />
						</IconLink>
					</Tooltip>
				</FooterItem>

				<FooterItem>
					<Tooltip text={['Discord']} clickable={false}>
						<IconLink href="https://discord.gg/the-sevens-gbtw" target="_blank" rel="noreferrer">
							<FaDiscord style={{ width: '24px', height: '24px' }} />
						</IconLink>
					</Tooltip>
				</FooterItem>
				<li className="mr-0">
					<Tooltip text={['Instagram']} clickable={false}>
						<IconLink href="https://www.instagram.com/thesevensofficial_777/" target="_blank" rel="noreferrer">
							<AiFillInstagram style={{ width: '24px', height: '24px' }} />
						</IconLink>
					</Tooltip>
				</li>
			</SocialContainer>
			<p className="text2 mt-xxxl text-center">
				<a href="https://twitter.com/@thesevens_7" target="_blank" rel="noreferrer">
					@thesevens_7
				</a>
				, designed with &#128150; and drawn with a whole lot of 🍷.
			</p>
			<p className="text2 mt-md text-center">
				<a href="mailto:info@thesevensofficial.com">info@thesevensofficial.com</a>
			</p>
		</SectionContainer>
	)
}

const ContentWrapper = styled.div`
	width: 560px;
	@media (max-width: 992px) {
		width: 100%;
		margin-top: 300px;
	}
`

const SectionContainer = styled.div`
	margin: 124px 24px 124px 24px;
	position: relative;
`

const SocialContainer = styled.ul`
	display: flex;
	list-style: none;
	position: absolute;
	right: 0;
	bottom: 96px;
	@media (max-width: 992px) {
		position: static;
		margin-top: 32px;
		margin-left: auto;
		margin-right: auto;
		justify-content: center;
	}
`

const FooterItem = styled.li`
	margin-right: 24px;
`

const IconLink = styled.a`
	text-decoration: none;
	color: inherit;
	&:hover,
	&:focus {
		text-decoration: none;
	}
`
