import React, { useContext, useState } from 'react'

interface IContext {
	isLoading: boolean
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined
}

export function useLoading(): IContext {
	return useContext(LoadingContext)
}

export const LoadingContext = React.createContext<IContext>({
	isLoading: true,
	setIsLoading: undefined,
})

export const LoadingProvider: React.FunctionComponent = ({ children }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<LoadingContext.Provider
			value={{
				isLoading,
				setIsLoading,
			}}
		>
			{children}
		</LoadingContext.Provider>
	)
}
