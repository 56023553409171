import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useRpc } from './useRpc'
import { TheSevens, TheSevens__factory } from '../contracts'

export interface IContext {
	contract?: TheSevens
}

export function useReadonlyContract(): IContext {
	return useContext(ReadonlyContractContext)
}

export const ReadonlyContractContext = React.createContext<IContext>({
	contract: undefined,
})

const ContractAddress = "0xf497253C2bB7644ebb99e4d9ECC104aE7a79187A"

export const ReadonlyContractProvider: React.FunctionComponent = ({ children }) => {
	const [contract, setContract] = useState<TheSevens | undefined>(undefined)
	const { provider: rpcProvider } = useRpc()

	const initContract = useCallback(() => {
		if (ContractAddress !== undefined) {
			if (rpcProvider !== undefined) {
				setContract(TheSevens__factory.connect(ContractAddress, rpcProvider))
			}
		}
	}, [rpcProvider])

	useEffect(() => {
		initContract()
	}, [initContract])

	return (
		<ReadonlyContractContext.Provider
			value={{
				contract,
			}}
		>
			{children}
		</ReadonlyContractContext.Provider>
	)
}
