import React, { useContext, useState, useEffect } from 'react'
import { providers } from 'ethers'

import { ETH_JSON_RPC } from '../constants/envVars'

interface IContext {
	provider: providers.JsonRpcProvider | undefined
}

export function useRpc(): IContext {
	return useContext(RpcContext)
}

export const RpcContext = React.createContext<IContext>({
	provider: undefined,
})

export const RpcProvider: React.FunctionComponent = ({ children }) => {
	const [provider, setProvider] = useState<providers.JsonRpcProvider | undefined>(undefined)

	useEffect(() => {
		setProvider(new providers.JsonRpcProvider(ETH_JSON_RPC))
	}, [])

	return (
		<RpcContext.Provider
			value={{
				provider,
			}}
		>
			{children}
		</RpcContext.Provider>
	)
}
