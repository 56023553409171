import { useState } from 'react'
import { Modal as ModalContainer } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import * as Styled from './Modal.styles'

export const useModal = () => {
	const [isShowing, setIsShowing] = useState(false)

	const toggle = () => setIsShowing(!isShowing)

	return {
		isShowing,
		toggle,
	}
}

interface ModalProps {
	isShowing: boolean
	toggle: () => void
	onExited?: () => void
	content: {
		title: string
		subTitle: string
		text: string
		image?: string
	}
}

const Modal = ({
	isShowing,
	toggle,
	onExited,
	content: { title = '', subTitle = '', text = '', image },
}: ModalProps) => {
	return isShowing
		? ReactDOM.createPortal(
				<ModalContainer
					show={isShowing}
					size="lg"
					backdropClassName="backstory-backdrop"
					onHide={toggle}
					onExited={onExited}
					centered
				>
					<div className="d-flex flex-column flex-lg-row overflow-hidden rounded-sm">
						{image && <img src={image} className="col-12 col-lg-5 p-0" alt="modal-img" />}
						<Styled.ModalBody>
							<Styled.ModalTitle>{title}</Styled.ModalTitle>
							<Styled.ModalSubTitle>{subTitle}</Styled.ModalSubTitle>
							<Styled.ModalText>{text}</Styled.ModalText>
						</Styled.ModalBody>
					</div>
					<Styled.Close onClick={toggle}>
						<span className="sr-only">close</span>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 9">
							<path
								fill="#F2F2F2"
								d="m4.88 4.5 2.5 2.53c.07.07.12.17.12.26 0 .12-.05.21-.12.26l-.58.58c-.07.1-.17.12-.26.12-.12 0-.21-.02-.26-.12l-2.53-2.5-2.53 2.5c-.07.1-.17.12-.26.12-.12 0-.21-.02-.26-.12l-.58-.58C.02 7.5 0 7.4 0 7.29c0-.1.02-.19.12-.26l2.5-2.53-2.5-2.53C.02 1.92 0 1.83 0 1.7c0-.1.02-.19.12-.26L.7.87C.75.8.84.75.96.75c.1 0 .19.05.26.12l2.53 2.5L6.28.88c.05-.07.14-.12.26-.12.1 0 .19.05.26.12l.58.58c.07.07.12.17.12.26 0 .12-.05.21-.12.26L4.88 4.5Z"
							/>
						</svg>
					</Styled.Close>
				</ModalContainer>,
				document.body,
		  )
		: null
}

export default Modal
