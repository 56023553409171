import React, { useCallback, useEffect, useState } from 'react'

import Container from '../components/Container'
import { useAccount } from '../hooks/useAccount'

import StakingBox from '../components/DojoBox/StakingBox'
import QuestionMark from '../components/media/questionMark.svg'
import DojoBg from '../components/media/dojoBg.png'
import Coins from '../components/media/coins.png'

import styled from 'styled-components'
import axios from 'axios'
import { STAKING_API, TOTAL_SUPPLY } from '../constants/envVars'
import { useContract } from '../hooks/useContracts'
import { toast } from 'react-toastify'
import { useLoading } from '../hooks/useLoading'
import Footer from '../components/footer/Footer'

export interface PoolData {
	startTime: string
	endTime: string
	rewardPerTokenPerSecond: string
	stakedTokenCount: number
}

export interface SevensToken {
	tokenId: number
	staked: boolean
	image: string
}
export interface StakingData {
	poolData: PoolData
	address: string
	zeniBalance: string
	sevensTokens: SevensToken[]
	accumulatedReward: string
	lastActionTime: string | null
}

export enum TokenStatus {
	Staked = 'Staked',
	NonStaked = 'NonStaked',
}

export interface WalletDetailsBoxProps {
	title: string
	content: number | string | JSX.Element
}

export interface RewardsParams {
	accumulatedReward: number
	rewardPerTokenPerSecond: number
	lastActionTime: string
	stakedTokensArrayLength: number
}

export default function DojoOld() {
	const { account, requestConnected } = useAccount()
	const { contracts } = useContract()
	const { setIsLoading } = useLoading()
	const [totalStakedToken, setTotalStakedToken] = useState<number>(0)
	const [balance, setBalance] = useState<string>('0')
	const [reward, setReward] = useState<number>(0)
	const [remainingTrainingTime, setRemainingTrainingTime] = useState<number>(0)
	const [stakedTokens, setStakedTokens] = useState<SevensToken[]>([])
	const [nonStakedtokens, setNonStakedTokens] = useState<SevensToken[]>([])
	const [waittingTxConfirm, setWaittingTxConfirm] = useState<boolean>(false)
	const [rewardParams, setRewardParams] = useState<RewardsParams | undefined>(undefined)

	const updateRewards = (props: RewardsParams) => {
		setReward(
			props.accumulatedReward +
				Math.max(
					((1640113200000 - new Date(props.lastActionTime).getTime()) / 1000) *
						props.rewardPerTokenPerSecond *
						props.stakedTokensArrayLength,
					0,
				),
		)
	}

	const loadStakingData = useCallback(async () => {
		if (account === undefined) return
		axios
			.get(`${STAKING_API}data/${account}`)
			.then((res) => {
				let data: StakingData = res.data
				const tokens = data.sevensTokens
				let stakedTokensArray = []
				let nonStakedTokenArray = []
				if (tokens.length !== 0) {
					// setIsSevensHolder(true)
				}
				for (let i = 0; i < tokens.length; i++) {
					if (tokens[i].staked) {
						stakedTokensArray.push(tokens[i])
					} else {
						nonStakedTokenArray.push(tokens[i])
					}
				}
				setStakedTokens(stakedTokensArray)
				setNonStakedTokens(nonStakedTokenArray)

				if (data.lastActionTime === null) {
					setReward(0)
				} else {
					let rewardParams = {
						accumulatedReward: +data.accumulatedReward,
						rewardPerTokenPerSecond: +data.poolData.rewardPerTokenPerSecond,
						lastActionTime: data.lastActionTime,
						stakedTokensArrayLength: stakedTokensArray.length,
					}
					updateRewards(rewardParams)
					setRewardParams(rewardParams)
				}

				// setDailyRewards(+data.poolData.rewardPerTokenPerSecond * stakedTokensArray.length * 86400)

				setRemainingTrainingTime(
					Math.round((new Date(data.poolData.endTime).getTime() - new Date().getTime()) / 86400000),
				)

				setBalance(data.zeniBalance)
				setTotalStakedToken(data.poolData.stakedTokenCount ?? 0)
			})
			.catch((err) => console.log(err, 'Error in quering staking data'))
	}, [account])

	const checkSevensApproval = useCallback(async () => {
		if (contracts === undefined || account === undefined) return
		const isApprovedForAll = await contracts.theSevenContract.isApprovedForAll(
			account,
			contracts.theSevenDojoContract.address,
		)
		if (isApprovedForAll) {
			// setApprovalStatus(true)
		}
	}, [contracts, account])

	// const unstakeToken = useCallback(async () => {
	// 	if (contracts === undefined || stakedTokens.length === 0 || waittingTxConfirm) {
	// 		return
	// 	} else {
	// 		try {
	// 			setWaittingTxConfirm(true)
	// 			const tx = await submitUnStakeRequest()
	// 			const stakingToast = toast.loading(<div>Unstaking token(s)...</div>)
	// 			tx.wait().then(async () => {
	// 				toast.update(stakingToast, {
	// 					render: 'Token(s) unstaked successfully',
	// 					type: 'success',
	// 					isLoading: false,
	// 					closeButton: true,
	// 					autoClose: 5000,
	// 				})
	// 				await loadStakingData()
	// 				setSelectedStakedTokens([])
	// 				setWaittingTxConfirm(false)
	// 			})
	// 		} catch (err) {
	// 			console.log(err)
	// 			setWaittingTxConfirm(false)
	// 		}
	// 	}
	// }, [contracts, loadStakingData, stakedTokens.length, submitUnStakeRequest, waittingTxConfirm])

	// const stakeToken = useCallback(async () => {
	// 	if (contracts === undefined || nonStakedtokens.length === 0 || !approvalStatus || waittingTxConfirm) {
	// 		return
	// 	} else {
	// 		try {
	// 			setWaittingTxConfirm(true)
	// 			const tx = await submitStakeRequest()
	// 			const stakingToast = toast.loading(<div>Staking token(s)...</div>)
	// 			tx.wait().then(async () => {
	// 				toast.update(stakingToast, {
	// 					render: 'Token(s) staked successfully',
	// 					type: 'success',
	// 					isLoading: false,
	// 					closeButton: true,
	// 					autoClose: 5000,
	// 				})
	// 				await loadStakingData()
	// 				setSelectedNonStakedTokens([])
	// 				setWaittingTxConfirm(false)
	// 			})
	// 		} catch (err) {
	// 			console.log(err)
	// 			setWaittingTxConfirm(false)
	// 		}
	// 	}
	// }, [contracts, nonStakedtokens, approvalStatus, waittingTxConfirm, loadStakingData, submitStakeRequest])

	const claimRewards = useCallback(async () => {
		if (contracts === undefined || reward === 0 || waittingTxConfirm) {
			return
		} else {
			try {
				setWaittingTxConfirm(true)

				const estimatedGasLimit = await contracts.theSevenDojoContract.estimateGas.claimRewards()
				const tx = await contracts.theSevenDojoContract.claimRewards({
					gasLimit: estimatedGasLimit.mul(12).div(10),
				})
				const claimingToast = toast.loading(<div>Claiming rewards ...</div>)
				tx.wait().then(async () => {
					toast.update(claimingToast, {
						render: 'Rewards claimed successfully',
						type: 'success',
						isLoading: false,
						closeButton: true,
						autoClose: 5000,
					})
					await loadStakingData()
					setWaittingTxConfirm(false)
				})
			} catch (err) {
				console.log(err)
				setWaittingTxConfirm(false)
			}
		}
	}, [contracts, reward, waittingTxConfirm, loadStakingData])

	const openOpenSea = () => {
		window.open('https://opensea.io/collection/the-sevens-genesis')
	}

	useEffect(() => {
		setIsLoading!(true)
		const loadData = async () => {
			await loadStakingData()
			await checkSevensApproval()
			setIsLoading!(false)
		}
		loadData()
		setInterval(loadData, 60000)
	}, [loadStakingData, checkSevensApproval, setIsLoading])

	useEffect(() => {
		let timerId: any
		if (rewardParams === undefined || stakedTokens.length === 0) {
			return
		} else {
			timerId = setInterval(() => updateRewards(rewardParams), 5000)
		}
		return () => clearInterval(timerId)
	}, [rewardParams, stakedTokens.length])

	const ProgressBar = React.memo((props: { stakedTokenNumber: number }) => {
		return (
			<div className="mt-lg">
				<div className="flex justify-between text-sm font-semibold">
					<p>{`${((props.stakedTokenNumber / +TOTAL_SUPPLY) * 100).toFixed(2)}%`} In Training</p>
					<p>{props.stakedTokenNumber}/7000</p>
				</div>
				<div className="mt-sm rounded-xl bg-black">
					<ProgressLine
						theme={{
							ProgressBarPercentage: (props.stakedTokenNumber / +TOTAL_SUPPLY) * 100,
						}}
					/>
				</div>
			</div>
		)
	})

	const WalletDetailsBox = React.memo((props: WalletDetailsBoxProps) => {
		return (
			<div className="flex flex-wrap text-center border-black border-2">
				<span className="w-full py-3 text-sm mobile:text-s  font-bold">
					<p>{props.title}</p>
				</span>
				<span className="w-full bg-black text-white text-lg font-light word-md h-36 flex justify-center items-center mobile:h-24 mobile:text-32px">
					{props.content}
				</span>
			</div>
		)
	})

	const DisconnectedPage = React.memo(() => {
		return (
			<div className="text-gray-dark">
				<Container className="h-1/2 my-48 mobile:mb-xxl">
					<div className="m-auto my-xxxl w-2/3 text-center xsMobile:w-11/12 mobile:w-full">
						<h2 className="text-xl mono-bold text-black leading-normal tablet:mb-0 tablet:text-lg">
							The Sevens
							<br /> <span className="text-red">Training Dojo.</span>
						</h2>
						<div className="mt-xl text-sm font-semibold">
							<p className="w-full mt-md">
								Enrolling The Sevens in our Training Dojo enables them to earn rewards. The longer they train, the
								greater the rewards.
							</p>
							<div className="w-full mt-md">
								<p>Rewards will be paid in $ZENI - The new utility token for The</p>
								<div className="inline-flex">
									Sevens.
									<a href={'/dojo/#section2'} className="flex ml-2">
										<img src={QuestionMark} alt="qustionMark" className="mr-2 w-5" />
									</a>
								</div>
							</div>
							<p className="w-full mt-md">
								Only the strongest will remain
								<br /> Good luck, kohai
							</p>
						</div>
						{<ProgressBar stakedTokenNumber={totalStakedToken} />}
						<div className="mt-lg w-2/3 m-auto flex justify-around tablet:justify-between tablet:w-full cursor-pointer">
							<span className="bg-black text-white px-10 py-2 mobile:px-6" onClick={requestConnected}>
								Connect wallet
							</span>
							<span className="px-10 py-2 mobile:px-6 bg-white" onClick={openOpenSea}>
								Buy on OpenSea
							</span>
						</div>
					</div>
				</Container>
				<BlackBackground className="h-1/2 text-white flex flex-wrap items-center" id={'section2'}>
					<div className="mx-0 my-xxxl w-1/2 tablet:w-full ">
						<h2 className="text-lg text-start tablet:mb-0 word-md mobile:text-md">
							Earn $ZENI! <br />
							Our utility token.
						</h2>
						<div className="w-4/5 text-24px word-md tablet:w-full mobile:text-sm">
							<p style={{ fontSize: '18px' }}>
								Our utility token $ZENI is used in The Sevens universe for many things. The first use will be the free
								minting of The Sevens Companions (gas on you). Many more usecases will be revealed over time! Stay up to
								date and join our socials.
							</p>
							<div className="mt-md text-24px mobile:text-sm">
								<span className="font-bold mobile:text-24px">$ZENI tokenomics:</span>

								<ul className="list-none" style={{ fontSize: '18px' }}>
									<li>Max supply: 7,777,777</li>
									<li>S1 staking allocation = 2,719,500 (35%)</li>

									<li>Max reward per NFT = 388.5</li>

									<li>Cost of 1 x Companion = 350 $ZENI</li>

									<li> S2 staking allocation = 1,555,555 (20%) </li>

									<li> S3 staking allocation = 1,555,555 (20%)</li>

									<li>Other = Min of 1,947,167 (Marketing etc)</li>
								</ul>
							</div>
							<p className="italic mt-5xl mobile:mt-lg mobile:text-sm" style={{ fontSize: '18px' }}>
								The $ZENI token does not represent any monetary value and is merely a token in The Sevens ecosystem.
							</p>
						</div>
					</div>
					<img src={Coins} className="w-1/2 h-2/3 tablet:w-full mobile:px-4" alt="" />
				</BlackBackground>
				<Container>
					<Footer />
				</Container>
			</div>
		)
	})

	return (
		<div className="overflow-hidden">
			<DojoBackground />
			{account ? (
				<Container className="text-gray-dark mb-10">
					<div className="mt-20">
						<ProgressBar stakedTokenNumber={totalStakedToken} />
					</div>
					<div className="w-full flex mobile:flex-wrap justify-between mt-10">
						<div className="w-1/3 mr-4 mobile:w-full mobile:mr-0">
							<WalletDetailsBox title="Account Balance" content={`${+parseFloat(balance).toFixed(2)} $ZENI`} />
						</div>
						<div className="w-1/3 mr-4 mobile:w-full mobile:mr-0 mobile:mt-8">
							<WalletDetailsBox
								title={`${account!.slice(0, 6)}
            ...
            ${account!.slice(-5)}`}
								content={<span className="text-32px">{stakedTokens.length + nonStakedtokens.length} SEVENS</span>}
							/>
						</div>
						<div className="w-1/3 mobile:w-full mobile:mt-8">
							<WalletDetailsBox
								title="Training"
								content={`${remainingTrainingTime < 0 ? '0' : remainingTrainingTime} days`}
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-10 w-full mt-10 mobile:grid-cols-1">
						<StakingBox
							key={'Presentrewardsbox'}
							buttonDisable={waittingTxConfirm}
							title={`${stakedTokens.length} Sevens In Training`}
							subTitle={`${reward.toFixed(2)} $ZENI`}
							buttonText={'Claim now'}
							buttonFunction={claimRewards}
						/>
						<StakingBox
							key={'Dailyrewardsbox'}
							buttonDisable={waittingTxConfirm}
							title={`Current earnings`}
							subTitle={`Earning 0 $ZENI/day`}
						/>
						{/* <StakingBox
							key={'StakedTokensbox'}
							buttonDisable={waittingTxConfirm}
							title={`${stakedTokens.length} Sevens In Training`}
							buttonText={isSenvensHolder ? (selectedStakedTokens.length === 0 ? 'Unstake all' : `Unstake ${selectedStakedTokens.length}`) : ''}
							buttonFunction={unstakeToken}
							content={
								<div className="grid grid-cols-4 gap-y-7 gap-x-10 mt-10 m-auto h-68 mobile:w-5/6 mobile:grid-cols-3 mobile:gap-x-8">
									{stakedTokens.length > 0 &&
										stakedTokens.map((token) => {
											return (
												<div onClick={() => handleTokensSelection(token.tokenId, TokenStatus.Staked)} key={token.tokenId}>
													<Tokens img={token.image} id={token.tokenId} selected={selectedStakedTokens.includes(token.tokenId)} />
												</div>
											);
										})}
								</div>
							}
						/> */}
						{/* <StakingBox title="Staking" buttonDisable content={<>Staking has!</>} /> */}
						{/*<StakingBox*/}
						{/*    key={'nonStakedTokensbox'}*/}
						{/*    title={`${nonStakedtokens.length} Waiting`}*/}
						{/*    buttonText={*/}
						{/*        isSenvensHolder*/}
						{/*            ? !approvalStatus*/}
						{/*                ? 'Approve'*/}
						{/*                : selectedNonStakedTokens.length === 0*/}
						{/*                ? 'Train all'*/}
						{/*                : `Train ${selectedNonStakedTokens.length}`*/}
						{/*            : 'Buy on OpenSea'*/}
						{/*    }*/}
						{/*    buttonFunction={isSenvensHolder ? (approvalStatus ? stakeToken : requestApproval) : openOpenSea}*/}
						{/*    buttonDisable={waittingTxConfirm}*/}
						{/*    content={*/}
						{/*        <>*/}
						{/*            {!approvalStatus && isSenvensHolder && (*/}
						{/*                <div className="w-full">Allow Seven Dojo to manage your Sevens first</div>*/}
						{/*            )}*/}
						{/*            <div className="grid grid-cols-4 gap-y-7 gap-x-10 mt-10 m-auto h-68 mobile:w-5/6 mobile:grid-cols-3 mobile:gap-x-8 ">*/}
						{/*                {nonStakedtokens.length > 0 &&*/}
						{/*                    nonStakedtokens.map((token) => {*/}
						{/*                        return (*/}
						{/*                            <div*/}
						{/*                                onClick={() => handleTokensSelection(token.tokenId, TokenStatus.NonStaked)}*/}
						{/*                                key={token.tokenId}*/}
						{/*                            >*/}
						{/*                                <Tokens*/}
						{/*                                    img={token.image}*/}
						{/*                                    id={token.tokenId}*/}
						{/*                                    selected={selectedNonStakedTokens.includes(token.tokenId)}*/}
						{/*                                />*/}
						{/*                            </div>*/}
						{/*                        );*/}
						{/*                    })}*/}
						{/*            </div>*/}
						{/*        </>*/}
						{/*    }*/}
						{/*/>*/}
					</div>
				</Container>
			) : (
				<DisconnectedPage />
			)}
		</div>
	)
}

const BlackBackground = styled.div`
	width: 100%;
	height: 100%;
	padding: 5% 10%;
	background-color: black;
	@media (max-width: 600px) {
		padding: 0 4% 25%;
	}
`

const ProgressLine = styled.div`
	width: ${(props) => props.theme.ProgressBarPercentage}%;
	min-width: 1%;
	height: 20px;
	background-color: #eb1c24;
	padding: 8px;
	border-radius: 0.5rem;
	transition: 0.3s ease;
`

const DojoBackground = styled.div`
	width: 100vw;
	height: 1000px;
	background: url(${DojoBg}) no-repeat center center;
	background-size: cover;
	position: absolute;
	top: 0;
	z-index: -10;
	opacity: 10%;
	-moz-box-shadow: inset -8px -10px 8px 10px #fff;
	-webkit-box-shadow: inset -8px -10px 8px 10px #fff;
	box-shadow: inset -8px -10px 8px 10px #fff;
`
