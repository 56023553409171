import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import * as Styled from './Manifesto.styled'

const Manifesto = () => {
	return (
		<>
			<Container>
				<Row>
					<Col xs={12} md={9}>
						<Styled.Section>
							<Styled.Title>MANIFES7O.</Styled.Title>
							<Styled.Paragraph>
								The Sevens began as the dream of a passionate, young, and upcoming digital artist: Janz. Ever since he
								was a child, he liked to draw the things he saw in TV shows, on the internet and on the street. As he
								got older, and his skills developed, he wanted to create something truely special. An art collection
								that combined all the things he liked as a child, taking us all the way though his journey to where he
								is today: a young professional launching his career in digital art. With references from pop culture,
								anime, games, memes and movies, The Sevens appeals on a global scale, catering for the tastes of the
								young and old alike.
								<br />
								<br />
								Since the beginning, our inclusive community has been the beating heart of The Sevens. Their passion,
								ideas and dedication keep us focused, determined and enable us to fly in the face of adversity. We are{' '}
								<span>
									<Styled.Highlight>“Stronger Together”</Styled.Highlight>
								</span>
								. Our mission is to create and nurture a community that fosters innovation and collaboration within the
								NFT space and beyond. We believe in the collective ideas of everyone that shares the same values as us.
								We strive for these ideals by listening and giving our supporters the ability to make decisions as a
								collective organization.
								<br />
								<br />
								While The Sevens started off as a collection of 7000 randomly generated art pieces, it is evolving into
								so much more. The Sevens have started on a journey towards becoming a holistic anime brand and with our
								pilot animated series coming up we are focusing heavily on developing our animated story, where the NFTs
								become the main characters!
							</Styled.Paragraph>
						</Styled.Section>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default Manifesto
