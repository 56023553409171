import React, { useContext, useState } from 'react'

export enum Theme {
	LIGHT = 'light',
	DARK = 'dark',
}
interface IContext {
	theme: string
	setTheme: React.Dispatch<React.SetStateAction<string>> | undefined
}

export function useTheme(): IContext {
	return useContext(ThemeContext)
}

export const ThemeContext = React.createContext<IContext>({
	theme: Theme.LIGHT,
	setTheme: undefined,
})

export const ThemeProvider: React.FunctionComponent = ({ children }) => {
	const [theme, setTheme] = useState<string>(Theme.LIGHT)

	return (
		<ThemeContext.Provider
			value={{
				theme,
				setTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	)
}
