import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Header1 = styled.h1`
	line-height: 77px;
	font-size: 3.7em;
	font-family: 'RobotoRegular', sans-serif;
	font-weight: 800;
	margin-bottom: 24px;
	@media (max-width: 992px) {
		font-size: 28px;
		line-height: 40px;
		text-align: center;
	}
`

export const Header2 = styled.h3`
	line-height: 48px;
	font-family: 'RobotoMono', monospace;
	font-weight: 800;
	margin-bottom: 24px;
`

export const ProjectTitle = styled.h1`
	font-size: 28px;
	font-family: 'RobotoRegular', sans-serif;
	height: 96px;
	text-overflow: ellipsis;
	font-weight: 400;
	color: #4f4f4f;
	line-height: 48px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	@media (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
		height: auto;
		-webkit-line-clamp: 10;
	}
`

export const ProjectTitleBold = styled.span`
	font-family: 'RobotoBold', sans-serif;
`

export const Body1 = styled.p`
	font-family: 'RobotoMono', monospace;
	font-size: 18px;
	color: #4f4f4f;
	margin-bottom: 48px;
	@media (max-width: 992px) {
		width: 100%;
		font-size: 16px;
		text-align: center;
	}
`

export const Body2 = styled.p`
	font-family: 'RobotoMono', monospace;
	font-size: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`

export const Small = styled.p`
	font-family: 'RobotoMonoMedium', monospace;
	font-size: 12px;
	color: #4f4f4f;
	margin: 0;
	@media (max-width: 992px) {
		margin-left: auto;
		margin-right: auto;
	}
`

export const SubTags = styled.p`
	font-size: 12px;
	font-family: 'RobotoMono', monospace;
	margin-bottom: 4px;
`

export const StyledLink = styled(Link)`
	position: relative;
	padding: 2px 4px;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoMono', sans-serif;
	border-radius: 2px;
	transition: 0.2s ease;

	&:hover {
		color: #fff;
		text-decoration: none;
		box-shadow: inset 0 0 0 1px #eb1c24;
	}
`

export const StyledNavLink = styled(NavLink)`
	position: relative;
	padding: 2px 4px;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoMono', sans-serif;
	border-radius: 2px;
	transition: 0.2s ease;
	text-decoration-color: #eb1c24;

	&:not(.disabled):not(.active):hover {
		color: #fff;
		text-decoration: underline;
		text-decoration-color: #eb1c24;
	}

	&.active {
		background-color: #eb1c24;
		color: #fff;
	}

	&.disabled {
		color: #999;
	}
`

export const StyledDisabledLink = styled.span`
	position: relative;
	padding: 2px 4px;
	text-transform: uppercase;
	text-decoration: none;
	color: #999;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoMono', sans-serif;
	border-radius: 2px;
	transition: 0.2s ease;
`

export const StyledOutsideLink = styled.a`
	position: relative;
	padding: 2px 4px;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoMono', sans-serif;
	border-radius: 2px;
	transition: 0.2s ease;
	text-decoration-color: #eb1c24;

	&:not(.disabled):hover {
		color: #fff;
		text-decoration: underline;
		text-decoration-color: #eb1c24;
	}

	&.active {
		background-color: #eb1c24;
		color: #fff;
		text-decoration: underline;
		text-decoration-color: #eb1c24;

		&:hover {
			color: #fff;
			text-decoration: underline;
			text-decoration-color: #eb1c24;
		}
	}

	&.disabled {
		color: #999;
	}
`

export const MenuLinkContainer = styled.div`
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	font-family: 'RobotoRegular', sans-serif;
	margin-left: 32px;
	cursor: pointer;
	&:focus,
	&:hover {
		text-decoration: none;
		color: #fff;
	}
`
