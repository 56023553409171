import Web3 from 'web3'
import { toast } from 'react-toastify'
import sevensAbi from '../abis/TheSevens.json'
import eveAbi from '../abis/Eve.json'
import companionsAbi from '../abis/Companions.json'
import multicallAbi from '../abis/multicall.json'

export const TARGET_CHAIN_ID = 1 // parseInt(1);
export const ro_web3 = new Web3('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161')
export const web3 = new Web3(window.ethereum)

export const sevensContract = new web3.eth.Contract(sevensAbi, '0xf497253C2bB7644ebb99e4d9ECC104aE7a79187A')
export const eveContract = new web3.eth.Contract(eveAbi, '0xC3CD69649394bF1A18a7a0c7F90E4D0e4f1A9758')
export const companionsContract = new web3.eth.Contract(companionsAbi, '0x1D69a5CEe83381459a2589828dF5E8d82Ca2cB88')
export const multicallContract = new web3.eth.Contract(multicallAbi, '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441')

export const hasWeb3 = window.ethereum ? true : false
export async function connect(checkChain = true) {
	if (!hasWeb3) {
		console.error(`Injected web3 not found`)
		toast.error('You need to use a web3 application to connect!', {
			autoClose: false,
		})
		return [false, undefined]
	}
	if (checkChain) {
		const chainId = await web3.eth.getChainId()
		if (chainId !== TARGET_CHAIN_ID) {
			console.error(`Connected on an incorrect chain, chainId:${chainId}`)
			toast.error('Please connect to the correct chain!', { autoClose: false })
			return [false, undefined]
		}
	}
	try {
		const [account] = await web3.eth.requestAccounts()
		return [true, account]
	} catch (e) {
		console.error(`requestAccounts:`, e)
		toast.error(e)
		return [false, undefined]
	}
}

export async function isConnected() {
	try {
		if (!hasWeb3) {
			console.error(`Injected web3 not found`)
			return [false, undefined]
		}
		const [account] = await web3.eth.getAccounts()
		return [account ? true : false, account]
	} catch (e) {
		console.error(e)
		return [false, undefined]
	}
}

export async function sendTx(tx, params, catchErrors = true) {
	try {
		const [connected, address] = await connect()
		if (!connected) return

		const gasEstimation = await tx.estimateGas({ ...params, from: address }).catch((e) => e)
		if (typeof gasEstimation !== 'number') {
			const revertReason = gasEstimation?.message?.match(/execution reverted: (.+)("|')/)
			const error = revertReason?.at(1) ?? gasEstimation?.message ?? gasEstimation
			toast.error(error)
			return false
		}
		const gas = parseInt(gasEstimation * 1.5)
		const receipt = await tx.send({ ...params, from: address, gas })
		return receipt
	} catch (e) {
		if (!catchErrors) throw e
		console.error(e)
		const error = e.message ?? e?.toString() ?? e
		toast.error(error)
		return false
	}
}

export async function getSevensTokens(account) {
	if ((await web3.eth.getChainId()) !== 1) {
		throw new Error('Incorrect chain')
	}

	const balance = await sevensContract.methods.balanceOf(account).call()
	const calls = Array.from({ length: balance }, (_, index) => [
		sevensContract.options.address,
		sevensContract.methods.tokenOfOwnerByIndex(account, index).encodeABI(),
	])
	const { returnData: results } = await multicallContract.methods.aggregate(calls).call()
	return results.map((result) => parseInt(web3.eth.abi.decodeParameter('uint256', result)))
}

export async function getCompanionsTokens(account) {
	if ((await web3.eth.getChainId()) !== 1) {
		throw new Error('Incorrect chain')
	}
	const calls = Array.from({ length: 2572 }, (_, i) => [
		companionsContract.options.address,
		companionsContract.methods.ownerOf(i + 1).encodeABI(),
	])
	const { returnData } = await multicallContract.methods.aggregate(calls).call()
	const ownedTokens = []

	for (let i = 0; i < returnData.length; i++) {
		if (web3.eth.abi.decodeParameter('address', returnData[i]).toLowerCase() === account.toLowerCase()) {
			ownedTokens.push(i + 1 + '')
		}
	}
	return ownedTokens
}

export async function getEveTokens(account) {
	if ((await web3.eth.getChainId()) !== 1) {
		throw new Error('Incorrect chain')
	}

	const tokens = await eveContract.methods.tokensOfOwner(account).call()
	return tokens
}

export function getSignatureParameters(signature) {
	if (signature.length !== 132) throw new Error('Invalid signature length')
	const r = signature.slice(0, 66)
	const s = `0x${signature.slice(66, 130)}`
	let v = `0x${signature.slice(130, 132)}`
	v = web3.utils.hexToNumber(v)

	if (![27, 28].includes(v)) v += 27

	return { r, s, v }
}
