import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 80px;
	padding-bottom: 80px;
`

export const Title = styled.h1`
	margin-bottom: 48px;
	font-family: 'RobotoMonoMedium', monospace;
	color: #fff;
	font-size: 28px;
	font-weight: 700;
	line-height: 48px;
	word-spacing: 4px;
	text-align: center;
`

export const SubTitle = styled.p`
	margin-top: -32px;
	font-family: 'RobotoMono', monospace;
	font-size: 20px;
	font-weight: 500;
	line-height: 48px;
	word-spacing: 4px;
	text-align: center;
	color: rgba(0, 0, 0, 0.6);
`

export const Button = styled.button`
	align-self: center;
	padding: 12px 80px;
	background-color: #000;
	color: #fff;
	font-size: 24px;
	font-family: 'RobotoMonoBold', monospace;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}

	&:disabled {
		background-color: #777;
		cursor: not-allowed;
	}
`
