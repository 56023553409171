import { Row, Col, Container } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import * as Styled from './HomeEve.styled'
import { AiOutlineArrowRight } from 'react-icons/ai'

const HomeEve = () => {
	return (
		<>
			<Styled.HomeBackground />
			<Container>
				<Row>
					<Col>
						<Styled.Section>
							<Styled.Title className="mb-5">7HE SEVENS.</Styled.Title>
							<Styled.VideoContainer>
								<Styled.VideoWrapper width={4128} height={2322}>
									<ReactPlayer url="/videos/eve.mp4" controls className="react-player" width="100%" height="100%" />
								</Styled.VideoWrapper>
							</Styled.VideoContainer>
							<Styled.ButtonHighlight className="mt-3">
								<Link to="/the-cluster" className="text-white text-decoration-none">
									Enter Our World
								</Link>
								<AiOutlineArrowRight width={18} height={18} className="ml-2" />
							</Styled.ButtonHighlight>
						</Styled.Section>
					</Col>
				</Row>
			</Container>
			{/* <Notification text="The Sevens Eve - Minting April 17" linkLabel="More info" url="/the-sevens-eve"/> */}
		</>
	)
}

export default HomeEve
