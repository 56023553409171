import React, { useContext, useState, useCallback } from 'react'
import { useEffect } from 'react'
import { MetamaskStatus, useMetamask } from './useMetamask'

export enum WalletStatus {
	Connecting = 'Connecting',
	Disconnected = 'Disconnected',
	Loading = 'Loading',
	Ready = 'Ready',
	NonTargetChain = 'NonTargetChain',
}
interface IContext {
	account: string | undefined
	walletStatus: WalletStatus
	requestConnected: () => Promise<void>
}

export function useAccount(): IContext {
	return useContext(AccountContext)
}

export const AccountContext = React.createContext<IContext>({
	walletStatus: WalletStatus.Loading,
	account: undefined,
	requestConnected: async () => {},
})

export const AccountProvider: React.FunctionComponent = ({ children }) => {
	const [account, setAccount] = useState<string | undefined>(undefined)
	const [walletStatus, setWalletStatus] = useState<WalletStatus>(WalletStatus.Loading)

	const { metamask, status } = useMetamask()

	window.ethereum?.on('accountsChanged', (accounts: string[]) => {
		setAccount(accounts[0])
	})

	window.ethereum?.on('chainChanged', () => {
		window.location.reload()
	})

	const requestConnected = async () => {
		if (status === MetamaskStatus.Ready) {
			setWalletStatus(WalletStatus.Connecting)
			try {
				let accounts = await metamask?.send('eth_requestAccounts', [])
				setAccount(accounts[0])
			} catch (err: any) {
				if (err.code === 4001) {
					setWalletStatus(WalletStatus.Disconnected)
				} else {
					console.error(err)
				}
			}
		}
	}

	const getNetwork = useCallback(async () => {
		await metamask
			?.getNetwork()
			.then((network) => {
				if (network.chainId.toString() === "1") {
					setWalletStatus(WalletStatus.Ready)
				} else setWalletStatus(WalletStatus.NonTargetChain)
			})
			.catch((error) => console.log(`error occurs when getting Network. Details:${error}`))
	}, [metamask])

	const getConnectedAccount = useCallback(async () => {
		if (status === MetamaskStatus.NotInstalled) {
			setWalletStatus(WalletStatus.Disconnected)
		} else if (status === MetamaskStatus.Ready) {
			await metamask
				?.listAccounts()
				.then(async (accounts) => {
					if (accounts.length !== undefined && accounts.length !== 0) {
						setAccount(accounts[0])
						await getNetwork()
					} else setWalletStatus(WalletStatus.Disconnected)
				})

				.catch((error) => console.log(`error occurs when getting connected account. Details:${error}`))
		}
	}, [metamask, status, getNetwork])

	useEffect(() => {
		const loadAccount = async () => {
			await getConnectedAccount()
		}
		loadAccount()
	}, [account, getConnectedAccount])

	return (
		<AccountContext.Provider
			value={{
				account,
				walletStatus,
				requestConnected,
			}}
		>
			{children}
		</AccountContext.Provider>
	)
}
