import Fuse from 'fuse.js'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import SearchInput from './components/SearchInput'
import * as Styled from './TheSurvivors.styles'
import { SurvivorsList } from './components/SurvivorsList'
import { Survivor, SurvivorFilter } from '../../types'
import { connect, getCompanionsTokens, getEveTokens, getSevensTokens } from '../../utils/Web3Handler'
import { useAccount } from '../../hooks/useAccount'
import survivorsData from './survivorsData.json'

const formatSurvivors = (data: any[]) =>
	data.reduce((acc, { name, type, backstory, NFTs = [] }) => {
		const formattedItems = NFTs.map((id: number) => {
			const imgType = { sevens: 'sevens', eves: 'sevens_eves', companions: 'sevens_companions' }[type as SurvivorFilter]
			return {
				id: id + '',
				image: `https://mnnt.io/collections/${imgType}/low_res/${id}`,
				backstory,
				type,
				name,
			}
		})
		return [...acc, ...formattedItems]
	}, [])

export const PAGE_SIZE = 24

export type ActiveFilter = SurvivorFilter | 'all' | 'owned'

const TheSurvivors = () => {
	const [query, setQuery] = useState('')
	const [index, setIndex] = useState<any>(null)
	const [activeFilter, setActiveFilter] = useState<ActiveFilter>('all')
	const { account } = useAccount()

	const setFuseIndex = useCallback((data) => {
		setIndex(
			new Fuse(data, {
				keys: ['name', 'id'],
			}) as any,
		)
	}, [])
	const [tokens, setTokens] = useState<{ eves: string[]; sevens: string[]; companions: string[] }>({
		eves: [],
		sevens: [],
		companions: [],
	})

	const [searchResults, setSearchResults] = useState([])
	const [page, setPage] = useState(1)

	const survivors = useMemo(() => {
		let res = (formatSurvivors(survivorsData) as Survivor[]) || []
		if (activeFilter === 'owned') {
			res = res.filter((el) => {
				if (el.type === 'eves') {
					return tokens.eves.includes(el.id + '')
				}
				if (el.type === 'sevens') {
					return tokens.sevens.includes(el.id + '')
				}
				if (el.type === 'companions') {
					return tokens.companions.includes(el.id + '')
				}
				return false
			})
		} else if (activeFilter !== 'all') {
			res = res.filter((el) => el.type === activeFilter)
		}
		return res
	}, [activeFilter, tokens])

	const displaySurvivors: Survivor[] = query ? searchResults : survivors

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
		({ target: { value } }) => {
			setQuery(value)
			setPage(1)
			if (index) {
				setSearchResults(index.search(value).map(({ item }: { item: Survivor }) => item))
			}
		},
		[index],
	)
	useEffect(() => {
		let mounted = true
		const fetchOwnedTokens = async () => {
			try {
				const [eves, sevens, companions] = await Promise.all([
					getEveTokens(account),
					getSevensTokens(account),
					getCompanionsTokens(account),
				])
				if (mounted) {
					setTokens({ eves, sevens: sevens.map((el: number) => el + ''), companions })
				}
			} catch (err) {
				console.log(err)
			}
		}
		fetchOwnedTokens()
		return () => {
			mounted = false
		}
	}, [account])

	useEffect(() => {
		setFuseIndex(displaySurvivors)
	}, [activeFilter, displaySurvivors, setFuseIndex])

	const handleFilterChange = useCallback(
		async (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault()
			const name = event.currentTarget.name
			setQuery('')
			if (!account && name === 'owned') {
				await connect()
			}
			setActiveFilter(name as ActiveFilter)
		},
		[account],
	)

	return (
		<Container>
			<Styled.Section>
				<Styled.Title>7he survivors.</Styled.Title>
				<Styled.MenuContainer>
					<Styled.Menu>
						<Styled.MenuItem name="all" className={activeFilter === 'all' ? 'active' : ''} onClick={handleFilterChange}>
							All
						</Styled.MenuItem>
						<Styled.MenuItem
							name="sevens"
							className={activeFilter === 'sevens' ? 'active' : ''}
							onClick={handleFilterChange}
						>
							The sevens
						</Styled.MenuItem>
						<Styled.MenuItem
							name="eves"
							className={activeFilter === 'eves' ? 'active' : ''}
							onClick={handleFilterChange}
						>
							The Eves
						</Styled.MenuItem>

						<Styled.MenuItem
							name="companions"
							className={activeFilter === 'companions' ? 'active' : ''}
							onClick={handleFilterChange}
						>
							The Companions
						</Styled.MenuItem>
						<Styled.MenuItem
							name="owned"
							className={activeFilter === 'owned' ? 'active' : ''}
							onClick={handleFilterChange}
						>
							My Survivors
						</Styled.MenuItem>
					</Styled.Menu>
				</Styled.MenuContainer>

				<Styled.Paragraph>
					Every relationship a Seven has with another human is transactional and temporary in nature. A friend or ally
					is simply an individual with a common interest … well let’s be honest, common enemy.
				</Styled.Paragraph>

				<SearchInput value={query as any} onChange={handleChange} />
				<SurvivorsList data={displaySurvivors || []} page={page} activeFilter={activeFilter} />
				{(query ? searchResults : survivors).length > page * PAGE_SIZE && (
					<Styled.Button onClick={() => setPage((prevState) => prevState + 1)}>Load more</Styled.Button>
				)}
			</Styled.Section>
		</Container>
	)
}

export default TheSurvivors