import styled, { keyframes } from 'styled-components'

export default function LoadingContent() {
	return (
		<>
			<div className="w-4/5 m-auto flex flex-wrap items-center my-4 px-8 tablet:px-0 mobile:pb-0 mobile:w-full">
				<div className="w-full flex flex-wrap px-2 justify-between items-center">
					<Avatar className="h-12 w-full" />
					<div className="w-full mt-4 font-normal tracking-wide inline-flex word-md justify-between pt-1 mobile:w-full tablet:text-left ">
						<Line className="w-1/3 h-4" />
						<Line className="w-1/3 h-4" />
					</div>
				</div>
				<div className="w-full justify-center mt-xl mobile:mt-md">
					<Avatar className="w-1/2 h-16 m-auto mobile:w-full" />
				</div>
			</div>
		</>
	)
}

export function LoadingTable() {
	return (
		<>
			<Column className="w-full h-10 mt-2" />
			<Column className="w-full h-10 mt-2" />
		</>
	)
}

export function LoadingForm() {
	return (
		<>
			<div className="w-full border border-black inline-flex mt-lg py-6 tablet:block">
				<div className="w-7/12 flex items-center tablet:w-full">
					<Avatar className="w-11/12 h-3/4 m-auto " />
				</div>
				<div className="w-5/12 tablet:w-full px-8 tablet:px-4 tablet:mt-4 flex flex-wrap ">
					<div className="w-full text-s tablet:text-xs font-bold">
						<Avatar className="w-full h-40 px-4" />
					</div>
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
					<Column className="mt-2 h-12 border-black w-full" />
				</div>
			</div>
		</>
	)
}

export function LoadingButton() {
	return <Avatar className="w-1/4  h-12 mt-2 flex tablet:w-3/4 tablet:mt-6 " />
}

export function LoadingMask() {
	return (
		<div className="fixed top-0 left-0 bottom-0 w-full bg-black opacity-50 flex">
			<Loader />
		</div>
	)
}

const shineLine = keyframes`
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
`

const shineAvatar = keyframes`
  0% {
    background-position: -100px + 100;
  }

  40%,
  100% {
    background-position: 140px + 100;
  }
`

const shineColumn = keyframes`
  0% {
    background-position: -500px;
  }

  40% {
    background-position: -250px;
  }
  
  100% {
    background-position: 50px;
  }
`

const Avatar = styled.div`
	background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	animation: ${shineAvatar} 1.6s infinite linear;
`

const Line = styled.div`
	background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	animation: ${shineLine} 1.6s infinite linear;
`

const Column = styled.div`
	background-image: linear-gradient(100deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	animation: ${shineColumn} 1.6s infinite linear;
`

const Loader = styled.div`
	border: 16px solid #f3f3f3;
	border-top: 16px solid #eb1c24;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	margin: auto;
	animation: spin 2s linear infinite;
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
