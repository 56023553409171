import styled from 'styled-components'

export const Wrapper = styled.main`
	padding-top: 56px;
	padding-bottom: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Title = styled.h1`
	font-family: 'RobotoMono', monospace;
	font-weight: 900;
	text-align: center;
	font-size: 56px;
	margin-bottom: 40px;
	color: #fff;
`

export const Link = styled.a`
	display: inline-block;
	margin-top: 56px;
	margin-right: auto;
	margin-left: auto;
	font-family: 'RobotoMono', monospace;
	color: #000;
	font-weight: 700;
	text-decoration: underline;
`

export const GridContainer = styled.div`
	width: 100%;
	max-width: 800px;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	row-gap: 30px;

	@media (min-width: 992px) {
		gap: 30px;
	}
`

export const GridItem = styled.div`
	grid-column: span ${(props) => props.xs};

	@media (min-width: 992px) {
		grid-column: span ${(props) => props.md};
	}
`

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
	border: 2px solid #000;
	background-color: #fff;
`

export const CardContent = styled.div`
	padding: 64px 24px;
	text-align: ${(props) => props?.align ?? 'center'};
	${(props) => (props?.mt ? { marginTop: props.mt } : {})};
`

export const CardTitle = styled.span`
	display: block;
	margin-bottom: 12px;
	font-size: 28px;
	font-weight: 600;
	font-family: 'RobotoMono', monospace;
`

export const CardSubTitle = styled.span`
	display: block;
	margin-bottom: 8px;
	font-size: 20px;
	font-family: 'RobotoMono', monospace;
`

export const CardFooter = styled.div`
	display: flex;
	margin-top: ${(props) => (props?.mt ? props.mt : 'auto')};
	border-top: 1px solid #000;
`

export const CardFooterAction = styled.button`
	display: block;
	width: 100%;
	background-color: #000;
	color: #fff;
	border: 0;
	padding: 12px 8px;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	${(props) =>
		props.transparent && {
			backgroundColor: 'transparent',
			color: '#000',
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.1)',
			},
		}}
`

export const Dialog = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
`

export const DialogWindow = styled.div`
	max-width: 540px;
	width: 90%;
	position: relative;
	z-index: 2;
`

export const DialogBackdrop = styled.div`
	position: fixed;
	flex-grow: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.85);
`

export const Input = styled.input`
	flex-grow: 1;
	padding: 16px 12px;
	border: 1px solid #000;
`

export const InputWrapper = styled.div`
	display: flex;
	width: 100%;
	margin-top: 12px;
`

export const InputButton = styled.button`
	background-color: #000;
	color: #fff;
	border: 0;
	padding: 8px 16px;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	${(props) =>
		props.transparent && {
			backgroundColor: 'transparent',
			color: '#000',
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.1)',
			},
		}}
`
