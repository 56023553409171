import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/App'
import { BrowserRouter as Router } from 'react-router-dom'
import { MetamaskProvider } from './hooks/useMetamask'
import { AccountProvider } from './hooks/useAccount'
import { ContractProvider } from './hooks/useContracts'

import { RpcProvider } from './hooks/useRpc'
import { ReadonlyContractProvider } from './hooks/useReadonlyContract'
import { LoadingProvider } from './hooks/useLoading'

declare global {
	var ethereum: any
}

ReactDOM.render(
	<React.StrictMode>
		<LoadingProvider>
			<MetamaskProvider>
				<RpcProvider>
					<ReadonlyContractProvider>
						<AccountProvider>
							<ContractProvider>
								<Router>
									<App />
								</Router>
							</ContractProvider>
						</AccountProvider>
					</ReadonlyContractProvider>
				</RpcProvider>
			</MetamaskProvider>
		</LoadingProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)
