import { useState, useEffect } from 'react'
import * as Styled from './Dojo.styles'
import Container from '../../components/Container'
import Web3 from 'web3'
import oldDojoAbi from '../../abis/TheSevensDojo.json'
import newDojoAbi from '../../abis/TheSevensDojo2.json'
import zeniAbi from '../../abis/ZeniToken.json'
import sevensAbi from '../../abis/TheSevens.json'
import companionsAbi from '../../abis/Companions.json'
import { connect, hasWeb3, TARGET_CHAIN_ID } from '../../utils/Web3Handler.js'
import { toast } from 'react-toastify'

const readOnlyWeb3 = new Web3(
	`https://${TARGET_CHAIN_ID === 1 ? 'mainnet' : 'rinkeby'}.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161`,
)
const web3 = new Web3(window.ethereum)

const DOJO_ADDRESS = '0xD4f8B4826847CA01652ACaaF09e48b3cB96f72DF'
const DOJO2_ADDRESS = '0xddc37012f1Aec306a7082E28285ed9302C7dffEe'
const SEVENS_ADDRESS = '0xf497253C2bB7644ebb99e4d9ECC104aE7a79187A'
const COMPANIONS_ADDRESS = '0x1D69a5CEe83381459a2589828dF5E8d82Ca2cB88'
const ZENI_ADDRESS = '0x2E59D147962E2bB3fBdc52dc18CfBa2653C06Ccc'

const ro_oldDojoContract = new readOnlyWeb3.eth.Contract(oldDojoAbi, DOJO_ADDRESS)
const ro_newDojoContract = new readOnlyWeb3.eth.Contract(newDojoAbi, DOJO2_ADDRESS)
const ro_sevensContract = new readOnlyWeb3.eth.Contract(sevensAbi, SEVENS_ADDRESS)
const ro_companionsContract = new readOnlyWeb3.eth.Contract(companionsAbi, COMPANIONS_ADDRESS)
const ro_zeniContract = new readOnlyWeb3.eth.Contract(zeniAbi, ZENI_ADDRESS)

const oldDojoContract = new web3.eth.Contract(oldDojoAbi, DOJO_ADDRESS)

var companionsImageBaseURI = `https://companions-metadata.s3.eu-central-1.amazonaws.com/images`
var sevensMetadataBaseURI = `https://dweb.link/ipfs/QmRE9x8qTTRtvS3UxDtzMCVV9GJKBfD8TgUoym1ePireGU`

const DECIMALSBN = new web3.utils.toBN('1000000000000000000')
const { toBN } = web3.utils

const getUnstakedSevens = async (addr) => {
	const balanceOfAddr = await ro_sevensContract.methods.balanceOf(addr).call()
	return new Promise(async (res) => {
		const tokenIdPromises = []
		for (let i = 0; i < balanceOfAddr; i++) {
			tokenIdPromises.push(ro_sevensContract.methods.tokenOfOwnerByIndex(addr, i).call())
		}
		const tokens = (await Promise.all(tokenIdPromises)).sort((a, b) => parseInt(a) - parseInt(b))
		return res(tokens)
	})
}

const getUnstakedCompanions = async (addr) => {
	addr = web3.utils.toChecksumAddress(addr)
	return new Promise(async (res) => {
		const possibleOwnedTokensEvents = await ro_companionsContract.getPastEvents('Transfer', {
			filter: { to: addr },
			fromBlock: 0,
		})
		if (possibleOwnedTokensEvents.length === 0) return res([])
		const possibleOwnedTokens = [
			...new Set(possibleOwnedTokensEvents.map((eventData) => eventData.returnValues.tokenId)),
		]

		const ownedTokens = []
		const total = possibleOwnedTokens.length
		if (total === 0) return res(ownedTokens)
		var done = 0
		possibleOwnedTokens.forEach(async (tokenId) => {
			if ((await ro_companionsContract.methods.ownerOf(tokenId).call()) === addr) {
				ownedTokens.push(tokenId)
			}
			if (++done === total) {
				return res(ownedTokens.sort((a, b) => a - b))
			}
		})
	})
}

const getOwnedTokens = async (addr) => {
	const stakedTokensPromise = ro_newDojoContract.methods.walletOfOwner(addr).call({ from: addr, gas: 300000000 })
	const unstakedTokensPromises = Promise.all([getUnstakedSevens(addr), getUnstakedCompanions(addr)])
	const [stakedTokens, unstakedTokens] = await Promise.all([stakedTokensPromise, await unstakedTokensPromises])
	return [unstakedTokens[0], unstakedTokens[1], stakedTokens.sevensTokens, stakedTokens.companionsTokens]
}

/** @param {Array<Number>} tokenIds  */
const getImagesOfSevens = async (tokenIds) => {
	return new Promise(async (res) => {
		const total = tokenIds.length
		const images = {}
		if (total === 0) return res(images)

		var done = 0
		tokenIds.forEach(async (tokenId) => {
			try {
				const response = await fetch(`${sevensMetadataBaseURI}/${tokenId}`)
				if (!response.ok) return
				const { image } = await response.json()
				const CID = image.split('ipfs://')[1]
				images[tokenId] = `https://dweb.link/ipfs/${CID}`
			} catch (e) {
				console.error(e)
				toast.error(`Failed to fetch image for Sevens #${tokenId}, ${e.message ?? e}`)
				images[tokenId] = ''
			}
			if (++done === total) return res(images)
		})
	})
}
/** @param {String} addr */
const getOldStakedTokens = async (addr) => {
	return new Promise(async (res) => {
		const stakedEvents = await ro_oldDojoContract.getPastEvents('Staked', {
			fromBlock: 0,
			filter: {
				staker: addr,
			},
		})
		const possiblyStakedTokens = [
			...new Set(
				stakedEvents.map(({ returnValues }) => {
					return parseInt(returnValues.tokenId)
				}),
			),
		]

		const ownedTokens = []
		const total = possiblyStakedTokens.length
		var done = 0
		possiblyStakedTokens.forEach(async (tokenId) => {
			if (
				web3.utils.toChecksumAddress(addr.toLowerCase()) ===
				(await ro_oldDojoContract.methods.tokenOwners(tokenId).call())
			) {
				ownedTokens.push(tokenId)
			}
			if (++done === total) return res(ownedTokens)
		})
	})
}

const Dojo = () => {
	const [currentlyStaked, setCurrentlyStaked] = useState([])
	const [readyToStake, setReadyToStake] = useState([])
	const [ownedZeni, setOwnedZeni] = useState('-')
	const [address, setAddress] = useState(undefined)
	const [ownedSevens, setOwnedSevens] = useState('-')
	const [ownedCompanions, setOwnedCompanions] = useState('-')
	const [selectedToStake, setSelectedToStake] = useState([])
	const [selectedToUnstake, setSelectedToUnstake] = useState([])
	const [earningsPerDay, setEarningsPerDay] = useState('-')
	const [isConnected, setIsConnected] = useState(false)
	const [pendingTransaction, setPendingTransaction] = useState(undefined)
	const [stakedOnOldContract, setStakedOnOldContract] = useState(0)

	const disconnect = async () => {
		setIsConnected(false)
		setAddress(undefined)
		setEarningsPerDay('-')
		setOwnedSevens('-')
		setOwnedCompanions('-')
		setOwnedZeni('-')
		setReadyToStake([])
		setCurrentlyStaked([])
		setSelectedToStake([])
		setSelectedToUnstake([])
		setPendingTransaction(undefined)
	}

	const setupWeb3 = async (addr) => {
		if (!hasWeb3) return
		if (!addr) addr = (await web3.eth.getAccounts())[0]

		if (addr) {
			const ownedTokensPromise = getOwnedTokens(addr)
			const earningsOfAddrPerDayPromise = ro_newDojoContract.methods.getClaimAmount(addr, true).call()
			const earningsOfAddrPromise = ro_newDojoContract.methods.getClaimAmount(addr, false).call()
			const pendingEarningsPromise = ro_newDojoContract.methods.addressToPendingClaim(addr).call()
			const zeniBalancePromise = ro_zeniContract.methods.balanceOf(addr).call()

			const [
				[unstakedSevens, unstakedCompanions, stakedSevens, stakedCompanions],
				earningsOfAddrPerDay,
				zeniBalance,
			] = await Promise.all([
				ownedTokensPromise,
				earningsOfAddrPerDayPromise,
				earningsOfAddrPromise,
				pendingEarningsPromise,
				zeniBalancePromise,
			])
			const sevensBalance = unstakedSevens.length + stakedSevens.length
			const companionsBalance = unstakedCompanions.length + stakedCompanions.length

			const finalUnstakedCompanions = unstakedCompanions.map((id) => {
				return {
					id: id,
					type: 'companions',
					src: `${companionsImageBaseURI}/${id}.png`,
				}
			})
			const finalStakedCompanions = stakedCompanions.map((id) => {
				return {
					id: id,
					type: 'companions',
					src: `${companionsImageBaseURI}/${id}.png`,
				}
			})

			const sevensURLs = await getImagesOfSevens(stakedSevens.concat(unstakedSevens))

			const finalUnstakedSevens = unstakedSevens.map((id) => {
				return {
					id: id,
					type: 'sevens',
					src: sevensURLs[id],
				}
			})
			const finalStakedSevens = stakedSevens.map((id) => {
				return {
					id: id,
					type: 'sevens',
					src: sevensURLs[id],
				}
			})

			setReadyToStake(finalUnstakedSevens.concat(finalUnstakedCompanions))
			setCurrentlyStaked(finalStakedSevens.concat(finalStakedCompanions))

			setEarningsPerDay(toBN(earningsOfAddrPerDay).div(DECIMALSBN).toString())

			setOwnedSevens(sevensBalance)
			setOwnedCompanions(companionsBalance)
			setOwnedZeni(toBN(zeniBalance).div(DECIMALSBN).toString())

			setSelectedToStake([])
			setSelectedToUnstake([])
			setIsConnected(true)
			setAddress(addr)
			return
		} else {
			disconnect()
		}
	}

	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', function handleAccountsChanged(accounts) {
				disconnect()
				const [addr] = accounts
				setupWeb3(addr)
			})
		}

		setupWeb3()
	})

	useEffect(() => {
		async function checkForOldStakedTokens(addr) {
			if (!web3.utils.isAddress(addr)) return
			const balanceOfUser = parseInt((await ro_oldDojoContract.methods.stakerInfos(addr).call()).stakedCount)
			setStakedOnOldContract(balanceOfUser)
		}

		checkForOldStakedTokens(address)
	}, [address])

	const handleDisconnectOrConnect = async () => {
		try {
			if (isConnected) {
				disconnect()
			} else {
				const [connected, addr] = await connect()
				if (!connected || !addr) return
				setupWeb3(addr)
			}
		} catch (e) {
			console.error(e)
			toast.error(e.message ?? e)
		}
	}

	const handleUnstakeOldContract = async () => {
		const addr = address
		const tokens = await getOldStakedTokens(addr)
		await oldDojoContract.methods.unstake(tokens).send({ from: addr })
		window.location.reload()
	}
	const handleDenyUnstakeOldContract = () => {
		setStakedOnOldContract(0)
	}

	const handleClickUnstakedItem = (id) => () => {
		alert('Staking has finished, please unstake all your NFTs!')
		// const exists = selectedToStake.includes(id);

		// if (exists) {
		// 	setSelectedToStake(selectedToStake.filter((item) => item !== id));
		// } else {
		// 	setSelectedToStake([...selectedToStake, id]);
		// }
	}

	const handleClickStakedItem = (id) => () => {
		const exists = selectedToUnstake.includes(id)

		if (exists) {
			setSelectedToUnstake(selectedToUnstake.filter((item) => item !== id))
		} else {
			setSelectedToUnstake([...selectedToUnstake, id])
		}
	}

	const handleAcceptTransaction = async () => {
		const { TX, from } = pendingTransaction

		setPendingTransaction(undefined)
		const gasEstimation = await TX.estimateGas({ from }).catch((e) => e)
		if (isNaN(parseInt(gasEstimation))) {
			console.error(gasEstimation)
			const revertReason = gasEstimation
				.toString()
				.match(/"execution reverted: ([. a-z]+)/i)
				?.at(1)
			toast.warn(`Error while estimating gas!\n${revertReason ?? gasEstimation.message ?? gasEstimation}`, {
				autoClose: false,
			})
		}

		if (gasEstimation) await TX.send({ from, gas: parseInt(parseInt(gasEstimation) * 1.3) })
		else await TX.send({ from })
		setupWeb3()
	}

	const handleDenyTransaction = async () => {
		setPendingTransaction(undefined)
	}

	return (
		<>
			<Styled.DojoBackground />
			<Container>
				<Styled.Wrapper>
					{/* <Styled.TopRule>
                        <Styled.Dot />
                        <span>
                            Currently staking: {currentlyStakingSevensCount} The Sevens, {currentlyStakingCompanionsCount} Companions
                        </span>
                    </Styled.TopRule> <a href="/liquidity-pool" className="text-white">Click here for LP staking</a> <br/><br/> */}
					<Styled.GridContainer>
						<Styled.GridItem xs={12} md={4}>
							<Styled.Card>
								<Styled.CardContent>
									<Styled.CardSubTitle>Pending Rewards</Styled.CardSubTitle>
									<Styled.CardTitle>{/*{pendingRewards}*/}- $ZENI</Styled.CardTitle>
									<Styled.CardSwitchContainer>
										{/* <Switch
                                            label="Unstake all"
                                            onChange={handleToggleUnstakeAllOnClaim}
                                            popover="You can unstake or stake while claiming rewards from this contract. This is cheaper than doing it in separate transactions."
                                            checked={unstakeAllOnClaim}
                                        /> */}
									</Styled.CardSwitchContainer>
								</Styled.CardContent>
								<Styled.CardFooter>
									<Styled.CardFooterAction onClick={() => /* handleClaimZeni() */ {}}>
										Claiming $ZENI has been disabled
									</Styled.CardFooterAction>
								</Styled.CardFooter>
							</Styled.Card>
						</Styled.GridItem>

						<Styled.GridItem xs={12} md={4}>
							<Styled.Card>
								<Styled.CardContent>
									<Styled.CardSubTitle>
										{address
											? `${address.substring(0, 7)}...
										${address?.substring(address.length - 7)}`
											: ''}
									</Styled.CardSubTitle>
									<Styled.CardTitle style={{ marginBottom: 0 }}>
										{ownedZeni} Zeni
										<br />
										{ownedSevens} The Sevens
										<br />
										{ownedCompanions} Companions
									</Styled.CardTitle>
								</Styled.CardContent>
								<Styled.CardFooter>
									<Styled.CardFooterAction onClick={() => handleDisconnectOrConnect()}>
										{isConnected ? 'Disconnect' : 'Connect'}
									</Styled.CardFooterAction>
								</Styled.CardFooter>
							</Styled.Card>
						</Styled.GridItem>

						<Styled.GridItem xs={12} md={4}>
							<Styled.Card>
								<Styled.CardContent>
									<Styled.CardSubTitle>Current Earnings</Styled.CardSubTitle>
									<Styled.CardTitle style={{ marginTop: 24 }}>{/*{earningsPerDay}*/}- $ZENI / Day</Styled.CardTitle>
								</Styled.CardContent>
								{/* <Styled.CardFooter>
                                    <Styled.CardFooterAction onClick={() => handleCheckRewardsCalculation()}>Check Rewards Calculation</Styled.CardFooterAction>
                                </Styled.CardFooter> */}
							</Styled.Card>
						</Styled.GridItem>

						<Styled.GridItem xs={12} md={6}>
							<Styled.Card>
								<Styled.CardContent>
									<Styled.CardTitle>Currently staking</Styled.CardTitle>
									<Styled.CardSubTitle>Select one or more to unstake</Styled.CardSubTitle>
								</Styled.CardContent>
								<Styled.ImageGridWrapper>
									<Styled.ImageGrid>
										{currentlyStaked.map(({ id, src, type }) => (
											<Styled.ImageGridItem key={`${type}-${id}`} onClick={handleClickStakedItem(`${type}-${id}`)}>
												<Styled.ImageGridItemImage
													className={selectedToUnstake.includes(`${type}-${id}`) ? 'selected' : ''}
												>
													<img src={src} alt={`${type}-${id}`} />
												</Styled.ImageGridItemImage>
												<Styled.ImageGridItemFooter>#{id}</Styled.ImageGridItemFooter>
											</Styled.ImageGridItem>
										))}
									</Styled.ImageGrid>
								</Styled.ImageGridWrapper>
								<Styled.CardContent mt="auto">
									{/* <Styled.CardSwitchContainer>
                                        <Switch
                                            label="Claim pending rewards"
                                            onChange={handleToggleClaimPendingOnUnstake}
                                            popover="You can claim pending rewards while staking or unstaking from this contract. This is cheaper than doing it in separate transactions."
                                            checked={claimPendingOnUnstake}
                                        />
                                    </Styled.CardSwitchContainer> */}
								</Styled.CardContent>
								<Styled.CardFooter mt="none">
									<Styled.CardFooterAction transparent>
										Unstake All
									</Styled.CardFooterAction>
									<Styled.CardFooterAction>
										Unstake Selected
									</Styled.CardFooterAction>
								</Styled.CardFooter>
							</Styled.Card>
						</Styled.GridItem>

						<Styled.GridItem xs={12} md={6}>
							<Styled.Card>
								<Styled.CardContent>
									<Styled.CardTitle>Staking has finished</Styled.CardTitle>
									<Styled.CardSubTitle>You can no longer stake any more of your NFTs</Styled.CardSubTitle>
								</Styled.CardContent>
								<Styled.ImageGridWrapper>
									<Styled.ImageGrid>
										{readyToStake.map(({ id, src, type }) => (
											<Styled.ImageGridItem key={`${type}-${id}`} onClick={handleClickUnstakedItem(`${type}-${id}`)}>
												<Styled.ImageGridItemImage
													className={selectedToStake.includes(`${type}-${id}`) ? 'selected' : ''}
												>
													<img src={src} alt={`${type}-${id}`} />
												</Styled.ImageGridItemImage>
												<Styled.ImageGridItemFooter>#{id}</Styled.ImageGridItemFooter>
											</Styled.ImageGridItem>
										))}
									</Styled.ImageGrid>
								</Styled.ImageGridWrapper>
								<Styled.CardContent mt="auto">
									{/* <Styled.CardSwitchContainer>
										<Switch
											label="Claim pending rewards"
											onChange={handleToggleClaimPendingOnStake}
											popover="You can claim pending rewards while staking or unstaking from this contract. This is cheaper than doing it in separate transactions."
											checked={claimPendingOnStake}
										/>
										<Switch
											label="Unstake selected"
											onChange={handleToggleUnstakeSelectedOnStake}
											popover="You can unstake or stake while claiming rewards from this contract. This is cheaper than doing it in separate transactions."
											checked={unstakeSelectedOnStake}
										/>
									</Styled.CardSwitchContainer> */}
								</Styled.CardContent>
								{/* <Styled.CardFooter mt="none">
									<Styled.CardFooterAction onClick={() => handleStakeAll()} transparent>
										Stake All
									</Styled.CardFooterAction>
									<Styled.CardFooterAction onClick={() => handleStakeSelected()}>Stake Selected</Styled.CardFooterAction>
								</Styled.CardFooter> */}
							</Styled.Card>
						</Styled.GridItem>
					</Styled.GridContainer>
				</Styled.Wrapper>
			</Container>

			{pendingTransaction && (
				<Styled.Dialog>
					<Styled.DialogWindow>
						<Styled.Card>
							<Styled.CardContent align="left" padding={24}>
								In this transaction you will:
								<br />
								<br />
								<Styled.UnorderedList>
									<li>Unstake {pendingTransaction?.unstakeAmount} NFTs</li>
									{/* <li>Stake {pendingTransaction?.stakeAmount} NFTs</li> */}
									{/* <li>Claim {pendingTransaction?.claimAmount} $ZENI</li> */}
								</Styled.UnorderedList>
								<br />
								Are you sure?
							</Styled.CardContent>
							<Styled.CardFooter>
								<Styled.CardFooterAction transparent onClick={() => handleDenyTransaction()}>
									No, go back
								</Styled.CardFooterAction>
								<Styled.CardFooterAction onClick={() => handleAcceptTransaction()}>
									Confirm transaction
								</Styled.CardFooterAction>
							</Styled.CardFooter>
						</Styled.Card>
					</Styled.DialogWindow>
					<Styled.DialogBackdrop onClick={() => handleDenyTransaction()} />
				</Styled.Dialog>
			)}

			{stakedOnOldContract > 0 ? (
				<Styled.Dialog>
					<Styled.DialogWindow>
						<Styled.Card>
							<Styled.CardContent align="left" padding={24}>
								You still have {stakedOnOldContract} NFTs staking in our old contract. To be able to stake in our new
								contract, you need to unstake first. Please unstake before continuing.
							</Styled.CardContent>
							<Styled.CardFooter>
								<Styled.CardFooterAction transparent onClick={() => handleDenyUnstakeOldContract()}>
									No, go back
								</Styled.CardFooterAction>
								<Styled.CardFooterAction onClick={() => handleUnstakeOldContract()}>
									Unstake old
								</Styled.CardFooterAction>
							</Styled.CardFooter>
						</Styled.Card>
					</Styled.DialogWindow>
					<Styled.DialogBackdrop onClick={() => handleDenyTransaction()} />
				</Styled.Dialog>
			) : (
				<></>
			)}
			{
				/* showRewardsCalculation */ false ? (
					<Styled.Dialog>
						<Styled.DialogWindow>
							<Styled.Card>
								<Styled.CardContent align="left" padding={24}>
									<Styled.UnorderedList>
										<li>
											{currentlyStaked.filter(({ type }) => /sevens/i.test(type)).length} Sevens (7 $ZENI/day):{' '}
											{currentlyStaked.filter(({ type }) => /sevens/i.test(type)).length * 7} $ZENI/day
										</li>
										<li>
											{currentlyStaked.filter(({ type }) => /companions/i.test(type)).length} Companions (3 $ZENI/day):{' '}
											{currentlyStaked.filter(({ type }) => /companions/i.test(type)).length * 3} $ZENI/day
										</li>
										{currentlyStaked.filter(({ type }) => /companions/i.test(type)).length <
										currentlyStaked.filter(({ type }) => /sevens/i.test(type)).length ? (
											<li>
												{currentlyStaked.filter(({ type }) => /companions/i.test(type)).length} Combo (10 $ZENI/day):{' '}
												{currentlyStaked.filter(({ type }) => /companions/i.test(type)).length * 10} $ZENI/day
											</li>
										) : (
											<li>
												{currentlyStaked.filter(({ type }) => /sevens/i.test(type)).length} Combo (10 $ZENI/day):{' '}
												{currentlyStaked.filter(({ type }) => /sevens/i.test(type)).length * 10} $ZENI/day
											</li>
										)}
										<li>Total: {earningsPerDay} $ZENI/day</li>
										{/* <li>Season One — Post-Companion staking allocation = 2,000,000</li>
									<li>Staking a Season One The Sevens NFT = 7 $ZENI per day</li>
									<li>Staking a The Sevens Companion = 3 $ZENI per day</li>
									<li>Staking an S1 Sevens + Companion = 20 $ZENI per day</li> */}
									</Styled.UnorderedList>
								</Styled.CardContent>
								<Styled.CardFooter>
									<Styled.CardFooterAction>
										Close
									</Styled.CardFooterAction>
								</Styled.CardFooter>
							</Styled.Card>
						</Styled.DialogWindow>
						<Styled.DialogBackdrop/>
					</Styled.Dialog>
				) : (
					<></>
				)
			}
		</>
	)
}

export default Dojo
