import React, { useRef } from 'react'
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import * as Styled from './TheCluster.styled'

const TheCluster = () => {
	const history = useHistory()
	const sevensSectionRef = useRef(null)
	const dojoSectionRef = useRef(null)
	const orderSectionRef = useRef(null)

	const handleScrollTo = (ref) => (e) => {
		e.preventDefault()
		ref.current.scrollIntoView({ behavior: 'smooth' })
		history.push(`${history.location.pathname}#${ref.current.id}`)
	}

	return (
		<>
			<Styled.HomeBackground />
			<Container>
				<Row>
					<Col xs={12} md={9}>
						<Styled.Section>
							<Styled.Title>7HE CLUSTER.</Styled.Title>
							<Styled.Paragraph>
								In 1981, a catastrophic earthquake struck the continent of North America.
								<br />
								<br />
								Some say <Styled.Highlight>New Los Angeles</Styled.Highlight> – a sprawling technological megacity built
								out of the ruins – was born on that day, birthed from the rupture.
								<br />
								<br />
								A decade later, the city – heavily polluted, crime ridden, hedonistic, and morally corrupt – teeters on
								the edge of chaos. Its control lies in the hands of The Consortiums, mega-corporations warring for power
								while various criminal organizations fight for the scraps.
								<br />
								<br />
								In the city center known as <Styled.Highlight>The Cluster</Styled.Highlight>, monolithic skyscrapers of
								glass and steel loom heavy over the rest of the city sprawl. A vast network of highways emerge from out
								of The Cluster like veins branching out of the heart of a living creature, interlinking an endless urban
								landscape of smokestacks and neon lights.
								<br />
								<br />
								At the very edge of the sprawl exists The Void, a vast wasteland that hides many secrets.
								<br />
								<br />
								<a href="#the-dojo" onClick={handleScrollTo(dojoSectionRef)} className="text-white">
									<AiOutlineArrowDown width={18} height={18} />
								</a>
							</Styled.Paragraph>
						</Styled.Section>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={9}>
						<Styled.Section id="the-dojo" ref={dojoSectionRef}>
							<Styled.SubTitle>7HE DOJO.</Styled.SubTitle>
							<Styled.Paragraph>
								In the heart of The Cluster exists a covert club and bathhouse called{' '}
								<Styled.Highlight>The Dojo</Styled.Highlight>.
								<br />
								<br />
								Run by a secretive order of monks, The Dojo is the preferred place to conduct business among members of
								the Sevens and those in the underworld, particularly when business dictates the need for a physical
								exchange.
								<br />
								<br />
								Safety is not guaranteed, and on some nights, things get messy. Recently, several Sevens have been found
								murdered within The Dojo in grisly fashion. A mysterious organization known as the Black Hats has taken
								credit.
								<br />
								<br />
								<a href="#the-sevens" onClick={handleScrollTo(sevensSectionRef)} className="text-white">
									<AiOutlineArrowDown width={18} height={18} />
								</a>
							</Styled.Paragraph>
						</Styled.Section>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={9}>
						<Styled.Section id="the-sevens" ref={sevensSectionRef}>
							<Styled.SubTitle>7HE SEVENS.</Styled.SubTitle>
							<Styled.Paragraph>
								Within the city resides a techno warrior class known as <Styled.Highlight>The Sevens</Styled.Highlight>.
								<br />
								<br />
								Young, ruthless, and materialistic, they work for whoever is willing to pay them. They are mercenaries
								for hire, at times serving the warring mega-corporations and crime syndicates that run the city.
								<br />
								<br />
								With their true origins a mystery, The Sevens hone a formidable reputation as elusive hackers skilled in
								methods of cyberwarfare. While a computer terminal may be their primary weapon of choice, their penchant
								for physical violence should not be underestimated.
								<br />
								<br />
								Some Sevens operate by their own code of ethics. Other Sevens can be more unpredictable.
								<br />
								<br />
								<a href="#the-order" onClick={handleScrollTo(orderSectionRef)} className="text-white">
									<AiOutlineArrowDown width={18} height={18} />
								</a>
							</Styled.Paragraph>
						</Styled.Section>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={9}>
						<Styled.Section id="the-order" ref={orderSectionRef}>
							<Styled.SubTitle>7HE ORDER.</Styled.SubTitle>
							<Styled.Paragraph>
								It happened shortly after the earthquake. Amongst the ruins, bizarre temples seemed to spring up
								overnight. An enigmatic body of monks would eventually take credit. They referred to themselves simply
								as {/*<Link to="/the-survivors"className="text-white">*/}
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Wen? Soon ™</Tooltip>}>
									<Styled.Highlight>
										The Order
										<AiOutlineArrowRight width={18} height={18} className="ml-2" />
										{/*<AiOutlineArrowRight width={18} height={18} />*/}
									</Styled.Highlight>
								</OverlayTrigger>
								{/*</Link>*/}
								<br />
								<br />
								Secretive in nature and dismissed by many as a cult, The Order has grown immensely in size over the
								years, gaining far-reaching influence within the highest levels of business, government, and organized
								crime within the city of New Los Angeles. There have also been numerous Sevens who have been lured into
								The Order as clandestine members.
								<br />
								<br />
								While The Order’s leadership and true inner workings are a closely guarded secret, it is believed that
								members follow the bidding of a deity known to them as Zero-One. It is claimed by some that Zero-One is,
								in actuality, a sentient supercomputer of unknown origin, responsible for the enormous wealth and power
								that The Order currently enjoys today. Described as extremely cunning, but also erratic and paranoid by
								those who have managed to glimpse it, it’s been warned that Zero-One’s ultimate intentions remain a
								mystery, leaving the Order’s leadership fearful.
								<br />
								<br />
							</Styled.Paragraph>
						</Styled.Section>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default TheCluster
