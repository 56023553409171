import Container from '../../components/Container'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import * as Styled from './DiscordAuth.styles'

const web3 = new Web3(window.ethereum)

function getQueryVariable(variable, showToast = true) {
	var query = window.location.search.substring(1)
	var vars = query.split('&')
	for (var i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=')
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1])
		}
	}
	if (showToast) toast.error(`Query variable code not found!`)
}

export default function NewDiscordAuth() {
	const [showLoginDiscord, setShowLoginDiscord] = useState(true)
	const [isConnected, setIsConnected] = useState(false)
	var signPending = false

	async function handleConnectClick() {
		try {
			if (!window.ethereum)
				return toast.error('You need to use a web3 enabled browser, or an extension that adds web3 functionality!', {
					autoClose: false,
				})
			if (signPending) return toast.warn('You already have a signature request pending')
			signPending = true

			const code = getQueryVariable('code') // window.location.search.match(/code=([a-zA-Z0-9]+)(&|)/)?.at(1);

			if (!code) return toast.error('Please use the URL provided in the discord server!')

			const [address] = await web3.eth.requestAccounts().catch(() => [undefined])
			if (!address) {
				signPending = false
				return
			}

			const signature = await web3.eth.personal
				.sign(`Sign this message to verify the ownership of your address\nDiscord Code ${code}`, address)
				.catch(() => undefined)
			if (!signature) {
				signPending = false
				return toast.warn('You need to sign the message in order for us to be able to verify your address!')
			}
			const data = { signature, address, discordCode: code }
			const response = await fetch('https://sevens-discord-verification.herokuapp.com/auth', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
			if (!response.ok) {
				signPending = false
				const text = await response.text()
				console.error(text)
				toast.error(
					<div>
						{text}
						<br />
						Click here to try again
					</div>,
					{
						autoClose: false,
						onClick: () => {
							window.location.search = ''
						},
					},
				)
				return
			}
			const { discordHandle } = await response.json()
			setIsConnected(true)
			toast.success(
				`Successfully connected ${`${address.substring(0, 4)}...${address.substring(
					address.length - 4,
				)}`} with ${discordHandle}`,
			)
		} catch (e) {
			toast.error(e)
			console.error(e)
		}
	}

	const handleLoginToDiscord = () => {
		window.location =
			'https://discord.com/api/oauth2/authorize?client_id=932655847442636880&redirect_uri=https%3A%2F%2Fthesevensofficial.com%2Fdiscord-auth&response_type=code&scope=identify'
	}

	useEffect(() => {
		async function setup() {
			if (!window.ethereum)
				toast.error('You need to use a web3 enabled browser, or an extension that adds web3 functionality!', {
					autoClose: false,
				})

			const code = getQueryVariable('code', false)
			if (code) setShowLoginDiscord(false)
		}
		setup()
	}, [])

	return (
		<Container>
			<Styled.Wrapper>
				<Styled.Title>
					{showLoginDiscord && 'Step 1: Login to Discord'}
					{!showLoginDiscord && !isConnected && 'Step 2: Connect your wallet'}
					{!showLoginDiscord && isConnected && 'Your wallet is now connected with your Discord account.'}
				</Styled.Title>
				{showLoginDiscord && <Styled.Button onClick={handleLoginToDiscord}>Login</Styled.Button>}
				{!showLoginDiscord && !isConnected && <Styled.Button onClick={handleConnectClick}>Connect</Styled.Button>}
				{!showLoginDiscord && isConnected && (
					<Styled.SubTitle>You will shortly get the roles relative to your holdings</Styled.SubTitle>
				)}
			</Styled.Wrapper>
		</Container>
	)
}
