import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Portal } from 'react-portal'
import styles from './HamburgerMenu.module.css'

const ScrollFix = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const Menu = ({ open, setOpen, items, socials }) => {
	const handleClose = () => {
		setOpen(false)
	}
	return (
		<div className={`${styles.menuWrapper} ${open ? `${styles.open}` : ''}`}>
			{open && <ScrollFix />}
			<button className={`${styles.burgerWrapper} ${styles.open} ${styles.burgerWrapperClose}`} onClick={handleClose}>
				<div />
				<div />
				<div />
			</button>
			<nav className={styles.menuWrapperInner}>
				{items.map(({ link, name, outsideLink, disabled }) => {
					if (outsideLink) {
						return <a href={link}>{name}</a>
					}

					if (disabled) {
						return <a className={styles.disabledItem} href="/">{name}</a>
					}

					return (
						<NavLink to={link} onClick={handleClose}>
							{name}
						</NavLink>
					)
				})}
				<p>Follow</p>
				{socials.map(({ url, title, icon: Icon }) => (
					<a href={url} target="_blank" rel="noreferrer" className="d-inline-flex align-items-center">
						<Icon className="mr-3" /> {title}
					</a>
				))}
			</nav>
		</div>
	)
}

export default function HamburgerMenu({ items, socials }) {
	const [open, setOpen] = React.useState(false)
	const node = React.useRef(null)
	return (
		<Container>
			<div ref={node}>
				<button
					className={styles.burgerWrapper}
					onClick={() => {
						setOpen((prev) => !prev)
					}}
				>
					<div />
					<div />
					<div />
				</button>
				<Portal>
					<Menu open={open} setOpen={setOpen} items={items} socials={socials} />
				</Portal>
			</div>
		</Container>
	)
}

const Container = styled.div`
	z-index: 99;
	display: none;

	@media (max-width: 992px) {
		display: block;
		margin-left: auto;
	}
`
