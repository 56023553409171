import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export default function CustomToastContainer() {
	return <StyledContainer position="bottom-right" />
}

const StyledContainer = styled(ToastContainer).attrs({})`
	.Toastify__toast {
		font-size: 14px;
		color: #141b21;
		border: 2px solid black;
		border-radius: 0;
		font-family: 'RobotoMono', monospace;
		font-weight: 400;
	}
`
