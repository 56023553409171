import styled from 'styled-components'

export const Section = styled.section`
	display: flex;
	justify-content: center;
	flex-direction: column;
	min-height: 80vh;
	width: 100%;
	padding-top: 120px;
	padding-bottom: 120px;
`

export const Title = styled.h1`
	font-family: 'AkiraExpanded', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 80px;
	line-height: 88px;
	color: #fff;
	text-transform: uppercase;

	@media (max-width: 992px) {
		font-size: 48px;
		line-height: 56px;
	}
`
export const SubTitle = styled.h2`
	font-family: 'AkiraExpanded', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 40px;
	line-height: 48px;
	color: #fff;
	text-transform: uppercase;

	@media (max-width: 992px) {
		font-size: 48px;
		line-height: 56px;
	}
`

export const Paragraph = styled.p`
	font-family: 'Roboto Mono', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
`

export const Highlight = styled.span`
	position: relative;
	font-weight: 700;

	&:before {
		background-color: #eb1c24;
		content: '';
		position: absolute;
		width: 100%;
		height: 15px;
		z-index: -1;
		transform: rotate(-2deg);
		margin-top: 5px;
	}
`

export const HomeBackground = styled.div`
	width: 100vw;
	height: 100vh;
	background: url('/images/sevens_cluster_bg_s.png') no-repeat center center;
	background-size: cover;
	position: fixed;
	top: 0;
	z-index: -10;

	@media (min-width: 768px) {
		background-image: url('/images/sevens_cluster_bg_m.png');
	}

	@media (min-width: 1024px) {
		background-image: url('/images/sevens_cluster_bg_l.png');
	}

	@media (min-width: 1200px) {
		background-image: url('/images/sevens_cluster_bg_xl.png');
	}
`

export const Image = styled.div`
	position: sticky;
	bottom: 0;
	margin-top: auto;

	img {
		//position: absolute;
		//top: 0;
		//left: -50%;
		margin-left: -50%;
		width: 200%;
		max-width: initial;
	}
`

export const ImageDojo = styled.div`
	width: 100%;
`

export const Footer = styled.div`
	padding: 64px 0 64px 0;
	width: 100%;
	height: 100%;
	background-color: black;
	color: white;
`
