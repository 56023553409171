import axios from 'axios'
import clsx from 'clsx'
import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaCheck } from 'react-icons/all'
import { createGlobalStyle } from 'styled-components'
import formatIpfsUrl from '../../utils/formatIpfsUrl'
import SearchInput from './components/SearchInput'
import * as Styled from './Revamp.styles'
import { connect, getSevensTokens, web3, getSignatureParameters } from '../../utils/Web3Handler'
import { toast } from 'react-toastify'

const GlobalStyles = createGlobalStyle`
  .backstory-backdrop {
    background-color: rgba(28, 28, 28, 0.9);
  }
`

const PAGE_SIZE = 24

const Revamp = () => {
	const [query, setQuery] = useState(null)
	const [index, setIndex] = useState(null)
	const [nfts, setNFTs] = useState([])
	const [selectedNfts, setSelectedNfts] = useState([])
	const [searchResults, setSearchResults] = useState([])
	const [page, setPage] = useState(1)
	const [account, setAccount] = useState(null)

	useEffect(() => {
		let isMounted = true
		const fetchData = async () => {
			try {
				if (!isMounted) return
				const [isConnected, userAccount] = await connect()
				if (!isConnected) {
					if (isMounted) {
						setNFTs([])
						const fuse = new Fuse([], { keys: [] })
						setIndex(fuse)
					}
					return toast.error('You need to connect to your wallet!', { autoClose: false })
				}

				if (window.ethereum) {
					window.ethereum.on('accountsChanged', ([account]) => {
						if (account) {
							return fetchData()
						} else {
							if (isMounted) {
								setNFTs([])
								const fuse = new Fuse([], { keys: [] })
								setIndex(fuse)
							}
							toast.error('You need to connect to your wallet!', { autoClose: false })
							return fetchData()
						}
					})
				}
				const ids = await getSevensTokens(userAccount)

				if (ids.length === 0) {
					return toast.error("You don't own any sevens!", { autoClose: false })
				}

				const response = await axios.get(`https://api.mnnt.io/collections/sevens/tokens?owner=${userAccount}`)

				if (response.status !== 200) {
					console.error(response)
					toast.error('Error while fetching from api')
					return
				}

				/** @type {{ token_id: number, metadata: { Revamped: boolean } }[]} */
				const data = response.data

				const formattedData = data.map(({ token_id, metadata }) => ({
					id: token_id,
					useRevamp: metadata.Revamped,
				}))

				if (isMounted) {
					setAccount(userAccount)
					setNFTs(formattedData)
					const fuse = new Fuse(formattedData, {
						keys: ['name', 'id'],
					})
					setIndex(fuse)
				}
			} catch (e) {
				console.error(e)
				toast.error(e?.message ?? e?.code ?? e?.status ?? e)
			}
		}

		fetchData()

		return () => {
			isMounted = false
		}
	}, [])

	const handleChange = (event) => {
		setQuery(event.target.value)
		setPage(1)
		setSearchResults(index.search(event.target.value).map(({ item }) => item))
	}

	const handleClickNft = (_id, useRevamp) => {
		setSelectedNfts((prevState) => {
			if (prevState.some(({ id }) => _id === id)) {
				return prevState.filter(({ id }) => id !== _id)
			}
			return [...prevState, { id: _id, useRevamp }]
		})
	}

	const selected = {
		originals: selectedNfts.filter(({ useRevamp }) => !useRevamp),
		revamps: selectedNfts.filter(({ useRevamp }) => useRevamp),
	}

	const handleRevamp = async (type) => {
		const changes = selected[type].map(({ id }) => ({ tokenId: parseInt(id), useRevamp: type === 'originals' }))
		console.log(JSON.stringify(changes))
		const signature = await web3.eth.personal.sign(JSON.stringify(changes), account, '')
		const { v, r, s } = getSignatureParameters(signature)
		const response = await axios
			.post(
				`https://api.mnnt.io/sevens/toggle_revamp/`,
				{ request: changes, eth_address: account, signature: { v, r, s } },
				{ headers: { 'content-type': 'application/json' } },
			)
			.catch((e) => e)
		if (response instanceof Error) {
			console.error(response)
			toast.error(response.message)
		} else {
			window.location.reload()
			// toast.success(response.statusText);
		}
	}

	return (
		<>
			<GlobalStyles />
			<Container>
				<Styled.Section>
					<Styled.Title>Revamp</Styled.Title>
					<Styled.Paragraph className="mb-5">
						Select your NFT's and click the button to start the revamp process.
					</Styled.Paragraph>
					<SearchInput value={query} onChange={handleChange} />
					<Row>
						{(query ? searchResults : nfts).slice(0, page * PAGE_SIZE).map(({ id, name, useRevamp }) => {
							const isSelected = selectedNfts.some(({ id: _id }) => id === _id)

							const imageSrc = formatIpfsUrl(
								useRevamp
									? `https://mnnt.io/collections/sevens/revamp/low_res/${id}`
									: `https://mnnt.io/collections/sevens/low_res/${id}`,
							)
							return (
								<Col xs={6} sm={4} md={3} lg={2} key={id} className="pb-4">
									<Styled.ItemImage onClick={() => handleClickNft(id, useRevamp)}>
										<img alt={`${name} #${id}`} src={imageSrc} />
										<Styled.ItemImageOverlay className={`item-image-overlay${isSelected ? ' selected' : ''}`}>
											{isSelected && <FaCheck size={40} />}
											{!isSelected && (
												<>
													Click
													<br />
													to
													<br />
													select
												</>
											)}
										</Styled.ItemImageOverlay>
									</Styled.ItemImage>
									<div className="d-flex justify-content-between">
										<Styled.ItemSubTitle>#{id}</Styled.ItemSubTitle>
									</div>
								</Col>
							)
						})}
					</Row>
					{(query ? searchResults : nfts).length > page * PAGE_SIZE && (
						<Styled.Button onClick={() => setPage((prevState) => prevState + 1)}>Load more</Styled.Button>
					)}
				</Styled.Section>
			</Container>
			<Styled.FloatingDiv className={clsx({ in: selectedNfts.length > 0 })}>
				<Styled.FloatingButton onClick={() => handleRevamp('originals')}>
					Start Revamp ({selected.originals.length})
				</Styled.FloatingButton>
				<Styled.FloatingButton onClick={() => handleRevamp('revamps')}>
					OG Art ({selected.revamps.length})
				</Styled.FloatingButton>
			</Styled.FloatingDiv>
		</>
	)
}

export default Revamp
