import styled from 'styled-components'
import DojoBg from '../../components/media/dojoBg.png'

export const Wrapper = styled.div`
	padding: 24px;
`

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	row-gap: 30px;

	@media (min-width: 992px) {
		gap: 30px;
	}
`

export const GridItem = styled.div`
	grid-column: span ${(props) => props.xs};

	@media (min-width: 992px) {
		grid-column: span ${(props) => props.md};
	}
`

export const DojoBackground = styled.div`
	width: 100vw;
	height: 1000px;
	background: url(${DojoBg}) no-repeat center center;
	background-size: cover;
	position: fixed;
	top: 0;
	z-index: -10;
	opacity: 0.1;
`

export const TopRule = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	margin-bottom: 24px;
`

export const Dot = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #69c26c;
`

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	border: 2px solid #000;
	background-color: #fff;
`

export const CardContent = styled.div`
	padding: ${(props) => props?.padding ?? 8}px;
	text-align: ${(props) => props?.align ?? 'center'};
	${(props) => (props?.mt ? { marginTop: props.mt } : {})};
`

export const CardTitle = styled.span`
	display: block;
	margin-bottom: 12px;
	font-size: 24px;
	font-weight: 600;
	font-family: 'RobotoMono', monospace;
`

export const CardSubTitle = styled.span`
	display: block;
	margin-bottom: 8px;
	font-size: 16px;
	font-family: 'RobotoMono', monospace;
`

export const CardFooter = styled.div`
	display: flex;
	margin-top: ${(props) => (props?.mt ? props.mt : 'auto')};
	border-top: 1px solid #000;
`

export const CardSwitchContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	> * {
		margin: 4px 12px;
	}
`

export const CardFooterAction = styled.button`
	display: block;
	width: 100%;
	background-color: #000;
	color: #fff;
	border: 0;
	padding: 8px;
	font-size: 14px;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba(0, 0, 0, 0.8);
	}

	${(props) =>
		props.transparent && {
			backgroundColor: 'transparent',
			color: '#000',
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.1)',
			},
		}}
`

export const ImageGridWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas: '. main main main main .';
	max-height: 500px;
	overflow: auto;
`

export const ImageGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	row-gap: 16px;
	column-gap: 30px;
	grid-area: main;
	margin-top: 24px;
	margin-bottom: 48px;
`

export const ImageGridItem = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	text-align: center;
	cursor: pointer;
`

export const ImageGridItemImage = styled.div`
	position: relative;
	padding-top: 100%;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		transition: background-color 0.2s ease;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 50%;
		width: 12px;
		height: 24px;
		z-index: 2;
		border-bottom: 3px solid #41cb35;
		border-right: 3px solid #41cb35;
		transform: rotate(45deg) translate(-30%, -50%);
		opacity: 0;
		transition: opacity 0.2s ease;
	}

	&:hover {
		&::before {
			background-color: rgba(0, 0, 0, 0.25);
		}
	}

	&.selected {
		&::before {
			background-color: rgba(0, 0, 0, 0.75);
		}
		&::after {
			opacity: 1;
		}
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

export const ImageGridItemFooter = styled.div`
	padding: 4px;
	background-color: #000;
	color: #fff;
	font-family: 'RobotoMono', monospace;
`

export const Dialog = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
`

export const DialogWindow = styled.div`
	max-width: 540px;
	width: 90%;
	position: relative;
	z-index: 2;
`

export const DialogBackdrop = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.85);
`

export const UnorderedList = styled.ul`
	list-style: disc;
	padding-left: 40px;
`
