import styled from 'styled-components'

export const ModalBody = styled.div`
	flex-grow: 1;
	padding: 40px 32px;
	background-color: #000;
	color: #fff;
	max-height: 350px;
	overflow-y: auto;
`

export const ModalTitle = styled.span`
	display: block;
	margin-bottom: 12px;
	font-weight: 800;
	font-size: 24px;
	line-height: 28px;
`

export const ModalSubTitle = styled.span`
	display: block;
	margin-bottom: 32px;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
`

export const ModalText = styled.p`
	display: block;
	margin-bottom: 24px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
`

export const Close = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	width: 32px;
	height: 32px;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.3);
	color: #fff;
	border: 0;
	border-radius: 50%;
	line-height: 0;

	svg {
		width: 16px;
		height: 16px;
	}
`
