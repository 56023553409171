import { useEffect, useState } from 'react'
import { Col, Container, Row, /* Button as BsButton, */ Form /* FormControl */ } from 'react-bootstrap'
import * as Styled from './ScavengerHunt.styles'
import { toast } from 'react-toastify'
import { connect, isConnected, web3, hasWeb3 } from '../../utils/Web3Handler.js'
import { v4 as uuidv4 } from 'uuid'

const minId = 0
const maxId = 6999

const apiURL = 'https://scavenger-hunt-backend.herokuapp.com/answer'
// const apiURL = 'http://localhost:8080/answer';

const ScavengerHunt = () => {
	const [address, setAddress] = useState(undefined)

	useEffect(() => {
		async function setupWeb3() {
			const [, address] = await isConnected()
			setAddress(address)
		}
		async function setupUUID() {
			if (!localStorage.getItem('uuid')) {
				const uuid = uuidv4()
				localStorage.setItem('uuid', uuid)
			}
		}
		setupUUID()
		if (hasWeb3) {
			window.ethereum.on('accountsChanged', (accounts) => {
				setAddress(accounts?.at(0))
			})
			setupWeb3()
		}
	}, [])

	const handleConnectWallet = async () => {
		const [, address] = await connect(false)
		setAddress(address)
	}

	const handleSubmit = (roundId) => {
		return async (e) => {
			e.preventDefault()

			const [, address] = await connect(false)
			setAddress(address)
			if (!address)
				return toast.error('You must connect your wallet and sign a message so we can verify your address', {
					autoClose: false,
				})

			const answer = document.getElementById(`answer-${roundId}`).value

			const input = parseInt(answer)
			if (!/^[0-9]+$/.test(answer) || input < minId || input > maxId)
				return toast.error(`You need to input an ID between ${minId}~${maxId} (inclusive)`, { autoClose: false })

			const signature = await web3.eth.personal.sign(
				`Sign this message to verify your picks!\nYour Answer: ${input}\n\nYour address: ${address}\n\n\nRound Id: ${roundId}`,
				address,
			)

			const params = new URLSearchParams([
				['address', address],
				['roundId', roundId],
				['answer', input],
				['signature', signature],
			])

			const response = await fetch(apiURL + '?' + params.toString(), {
				method: 'POST',
				headers: {
					uuid: localStorage.getItem('uuid'),
				},
			})

			if (response.ok) {
				return toast.success(`Successfully registered answer ${input}!`)
			} else {
				return toast.error(await response.text())
			}
		}
	}

	return (
		<Container>
			<Styled.Section>
				<Styled.Title>DCL Scavenger Hunt</Styled.Title>
				<Row>
					<Col xs={12} md={6}>
						<Styled.Paragraph>
							The Sevens need a hero... can you help us find them?
							<br />
							<br />
							Thank you for joining our second metaverse scavenger hunt. For the next four days (24-27th), we will be
							hiding 1 Sevens image in Decentraland a day. Find each image, answer the riddle attached to this website
							and you'll find your next clue.
							<br />
							<br />
							When you find the final image on Sunday, make sure to take a screenshot of your avatar next to the image
							and share it with us on Twitter. We will choose one person who shares their image and gift them an item
							from our newest wearables collection.
							<br />
							<br />
							To thank you for helping us on this important quest, we'll also be gifting participants with a POAP and
							Monk Bucket Hat (for Sevens holders). To make sure you get your prizes, please connect your wallet and
							submit the image ID you find each day on this site. Now get to work! The Sevens is counting on you."
						</Styled.Paragraph>
						<Styled.Button onClick={handleConnectWallet}>
							{!address
								? 'Connect Wallet'
								: `Connected with ${address.substr(0, 6)}..${address.substr(address.length - 4)}`}
						</Styled.Button>
					</Col>
				</Row>
			</Styled.Section>
			<Styled.Section>
				<Styled.Title as="h2">Day 4</Styled.Title>
				<Styled.Card className="mb-24">
					<Row>
						<Col xs={12} md={6}>
							<img src="/images/shday4.png" alt=""></img>
						</Col>
						<Col xs={12} md={6}>
							<Styled.Title as="h3" className="text-left h3 mt-8 mt-md-0">
								Your clue
							</Styled.Title>
							<Styled.Paragraph>
								<h2>-??? , -??</h2>
								<br />
								<br />
								1. The number of maple branches
								<br />
								2. The number of circles in the NFT
								<br />
								3. The number of "7"s in the image
								<br />
								4. The second NFT ID number
								<br />
								5. Number of blue elements in the NFT
								<br />
								<br />
								Follow the coordinates uncovered with these clues to find a poster of our leading lady and tweet a
								screenshot of yourself next to the poster and tag @thesevens_7.
							</Styled.Paragraph>
						</Col>
					</Row>{' '}
				</Styled.Card>
				<Styled.Title as="h2">Day 3</Styled.Title>
				<Styled.Card className="mb-24">
					<Row>
						<Col xs={12} md={6}>
							<img src="/images/shday3.png" alt=""></img>
						</Col>
						<Col xs={12} md={6}>
							<Styled.Title as="h3" className="text-left h3 mt-8 mt-md-0">
								Your clue
							</Styled.Title>
							<Styled.Paragraph>
								<h2>?/F -???, ??</h2>
								<br />
								<br />
								First number in the NFT ID
								<br />
								The number of skulls on the hat
								<br />
								The number of "7"s in top left corner
								<br />
								The number of "7"s on the mask
								<br />
								The number of letters on the mask
								<br />
								The number of chef knives
								<br />
							</Styled.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={12}>
							<Form className="mt-8" onSubmit={handleSubmit(27) /* Round ID */}>
								<label className="sr-only">Found NFT ID</label>
								<Styled.Input type="text" id="answer-27" placeholder="Found NFT ID..." />
								<Styled.Button block type="submit">
									Submit
								</Styled.Button>
							</Form>
						</Col>
					</Row>
				</Styled.Card>
				<Styled.Title as="h2">Day 2</Styled.Title>
				<Styled.Card className="mb-24">
					<Row>
						<Col xs={12} md={6}>
							<img src="/images/shday2.png" alt=""></img>
						</Col>
						<Col xs={12} md={6}>
							<Styled.Title as="h3" className="text-left h3 mt-8 mt-md-0">
								Your clue
							</Styled.Title>
							<Styled.Paragraph>
								<h2>?/F -???, -??</h2>
								<br />
								<br />
								1. The number of "7s" on the necklace + number of "7s" on hat
								<br />
								2. Number of necklaces
								<br />
								3. Number of tines on the weapon
								<br />
								4. Number of astronaut helmets
								<br />
								5. The last number in the NFT ID #<br />
								6. The number of katanas
								<br />
							</Styled.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={12}>
							<Form className="mt-8" onSubmit={handleSubmit(26) /* Round ID */}>
								<label className="sr-only">Found NFT ID</label>
								<Styled.Input type="text" id="answer-26" placeholder="Found NFT ID..." />
								<Styled.Button block type="submit">
									Submit
								</Styled.Button>
							</Form>
						</Col>
					</Row>
				</Styled.Card>
				<Styled.Title as="h2">Day 1</Styled.Title>
				<Styled.Card>
					<Row>
						<Col xs={12} md={6}>
							<img src="/images/sh-day1.png" alt=""></img>
						</Col>
						<Col xs={12} md={6}>
							<Styled.Title as="h3" className="text-left h3 mt-8 mt-md-0">
								Your clue
							</Styled.Title>
							<Styled.Paragraph>
								Use this image to decipher the clues. This will give you coordinates for the NFT ID you must find.
								<br />
								<br />
								1. The number of masks in the NFT
								<br />
								2. The number of weapons in the NFT
								<br />
								3. The first number in the NFT ID #<br />
								4. The number of 7s on his head
								<br />
								5. The number of wave items in the NFT
								<br />
							</Styled.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={12}>
							<Form className="mt-8" onSubmit={handleSubmit(25) /* Round ID */}>
								<label className="sr-only">Found NFT ID</label>
								<Styled.Input type="text" id="answer-25" placeholder="Found NFT ID..." />
								<Styled.Button block type="submit">
									Submit
								</Styled.Button>
							</Form>
						</Col>
					</Row>
				</Styled.Card>
			</Styled.Section>
		</Container>
	)
}

ScavengerHunt.propTypes = {}

ScavengerHunt.defaultProps = {}

export default ScavengerHunt
