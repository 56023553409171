import React, { useEffect, useState } from 'react'
import Container from '../../components/Container'
import lpStakingAbi from '../../abis/LPStaking.json'
import stTokenAbi from '../../abis/stTokenAbi.json'
import * as Styled from './LPPage.styles'
import { web3, ro_web3, sendTx, connect } from '../../utils/Web3Handler.js'
import { toast } from 'react-toastify'

const { toBN } = web3.utils
const DECIMALS = toBN('1000000000000000000')

const lpStakingAddress = '0x9e5Fa96Bf04491119b79cd8cea7A0a8a9a897B02'
const stTokenAddress = '0x0d5751e197830c7cdbf73d4c264fc00717534fb2'

const lpStakingContract = new web3.eth.Contract(lpStakingAbi, lpStakingAddress)
const ro_lpStakingContract = new ro_web3.eth.Contract(lpStakingAbi, lpStakingAddress)

const stTokenContract = new web3.eth.Contract(stTokenAbi, stTokenAddress)
const ro_stTokenContract = new ro_web3.eth.Contract(stTokenAbi, stTokenAddress)

const LPPage = () => {
	const [isConnected, setIsConnected] = useState(false)
	const [stakedAmount, setStakedAmount] = useState('-')
	const [earnedAmount, setEarnedAmount] = useState('-')
	const [activeDialog, setActiveDialog] = useState(null)
	const [amountToMutate, setAmountToMutate] = useState(0)
	const [availableTokens, setAvailableTokens] = useState('-')

	const handleClaim = () => {
		sendTx(lpStakingContract.methods.getReward(), {})
	}

	const handleClaimAndWithdraw = () => {
		sendTx(lpStakingContract.methods.exit(), {})
	}

	const handleCancelStake = () => {
		setActiveDialog(null)
		setAmountToMutate(0)
	}

	const handleStake = async () => {
		try {
			const amount = web3.utils.toWei(amountToMutate.toString(), 'ether')
			if (activeDialog === 'add') {
				// handle adding to LP
				const [connected, address] = await connect()
				if (!connected) return
				const stBalance = toBN(await ro_stTokenContract.methods.balanceOf(address).call())
				const hasEnoughBalance = stBalance.gte(toBN(amount))
				if (!hasEnoughBalance) {
					toast.error('Not enough balance')
					return
				}
				const hasEnoughAllowance = toBN(
					await ro_stTokenContract.methods.allowance(address, lpStakingAddress).call(),
				).gte(toBN(amount.toString()))
				if (!hasEnoughAllowance) {
					toast.info('A one time approval transaction is required to initialize the contract.')
					const success = await sendTx(stTokenContract.methods.approve(lpStakingAddress, DECIMALS.pow(toBN(3))), {})
					if (!success) return
				}
				sendTx(lpStakingContract.methods.stake(amount), {})
			} else {
				// handle removing from LP
				const [connected, address] = await connect()
				if (!connected) return

				const stakedBalance = toBN(await ro_lpStakingContract.methods.balanceOf(address).call())
				const hasEnoughBalance = stakedBalance.gte(toBN(amount))
				if (!hasEnoughBalance) {
					toast.error('Not enough balance')
					return
				}
				sendTx(lpStakingContract.methods.withdraw(amount), {})
			}

			setActiveDialog(null)
			setAmountToMutate(0)
		} catch (e) {
			console.error(e)
			const error = e.message ?? e
			toast.error(error)
		}
	}

	const updateState = async (address) => {
		try {
			if (!address) {
				setStakedAmount(0)
				setEarnedAmount(0)
				setAvailableTokens(0)
				setIsConnected(false)
				return
			}
			const stakedAmountPromise = ro_lpStakingContract.methods.balanceOf(address).call()
			const earnedAmountPromise = ro_lpStakingContract.methods.earned(address).call()
			const availableTokensPromise = ro_stTokenContract.methods.balanceOf(address).call()
			const [stakedAmount, earnedAmount, availableTokens] = await Promise.all([
				stakedAmountPromise,
				earnedAmountPromise,
				availableTokensPromise,
			])
			setStakedAmount(stakedAmount)
			setEarnedAmount(earnedAmount)
			setAvailableTokens(availableTokens)
			setIsConnected(true)
		} catch (e) {
			console.error(e)
			const error = e.message ?? e
			toast.error(error)
			setIsConnected(false)
		}
	}

	const handleInputMax = () => {
		setAmountToMutate(
			activeDialog === 'add'
				? web3.utils.fromWei(availableTokens.toString(), 'ether')
				: web3.utils.fromWei(stakedAmount.toString(), 'ether'),
		)
	}

	useEffect(() => {
		async function init() {
			if (window.ethereum) {
				window.ethereum.on('accountsChanged', ([account]) => {
					updateState(account)
				})
				async function updateData() {
					const [address] = await web3.eth.getAccounts()
					updateState(address)
				}
				updateData()
				setInterval(updateData, 10000)
				return
			}
			updateState(undefined)
		}
		init()
	}, [])

	const handleAdd = () => {
		setActiveDialog('add')
	}
	const handleRemove = () => {
		setActiveDialog('remove')
	}
	const handleConnectWallet = async () => {
		const [, account] = await connect()
		updateState(account)
	}

	if (!isConnected) {
		return (
			<Container>
				<Styled.Wrapper>
					<Styled.Title> ETH - ZENI LP Pool </Styled.Title>
					<Styled.GridContainer>
						<Styled.GridItem xs={2} md={3} />
						<Styled.GridItem xs={8} md={6}>
							<Styled.CardFooterAction onClick={handleConnectWallet}> Connect Wallet </Styled.CardFooterAction>
						</Styled.GridItem>
					</Styled.GridContainer>
				</Styled.Wrapper>
			</Container>
		)
	}

	return (
		<Container>
			<Styled.Wrapper>
				<Styled.Title> ETH - ZENI LP Pool </Styled.Title>
				<Styled.GridContainer>
					<Styled.GridItem xs={12} md={6}>
						<Styled.Card>
							<Styled.CardContent>
								<Styled.CardTitle> Stake LP </Styled.CardTitle>{' '}
								<Styled.CardTitle> {toBN(stakedAmount).muln(100).div(DECIMALS).toNumber() / 100} </Styled.CardTitle>
								<Styled.CardSubTitle> Staked </Styled.CardSubTitle>
							</Styled.CardContent>
							<Styled.CardFooter mt={2}>
								<Styled.CardFooterAction transparent onClick={handleRemove}>
									Remove
								</Styled.CardFooterAction>
								<Styled.CardFooterAction onClick={handleAdd}> Add </Styled.CardFooterAction>
							</Styled.CardFooter>
						</Styled.Card>
						<Styled.CardFooterAction
							as="a"
							href="https://app.uniswap.org/#/add/v2/ETH/0x2E59D147962E2bB3fBdc52dc18CfBa2653C06Ccc?chain=mainnet"
							target="zeni-eth-uniswap"
						>
							Add Liquidity on UNISWAP
						</Styled.CardFooterAction>
					</Styled.GridItem>
					<Styled.GridItem xs={12} md={6}>
						<Styled.Card>
							<Styled.CardContent>
								<Styled.CardTitle> Earnings </Styled.CardTitle>{' '}
								<Styled.CardTitle> {toBN(earnedAmount).muln(100).div(DECIMALS).toNumber() / 100} </Styled.CardTitle>
								<Styled.CardSubTitle> ZENI Earned </Styled.CardSubTitle>
							</Styled.CardContent>
							<Styled.CardFooter mt={2}>
								<Styled.CardFooterAction onClick={handleClaim}> Claim </Styled.CardFooterAction>
							</Styled.CardFooter>
						</Styled.Card>
						<Styled.CardFooterAction onClick={handleClaimAndWithdraw}> Claim & Withdraw </Styled.CardFooterAction>
					</Styled.GridItem>
				</Styled.GridContainer>
			</Styled.Wrapper>
			{activeDialog && (
				<Styled.Dialog>
					<Styled.DialogWindow>
						<Styled.Card>
							<Styled.CardContent align="left">
								How much would you like to {activeDialog} ?
								<Styled.InputWrapper>
									<Styled.Input
										type="number"
										value={amountToMutate}
										onChange={(e) => setAmountToMutate(e.target.value)}
									/>
									<Styled.InputButton onClick={handleInputMax}> Max </Styled.InputButton>
								</Styled.InputWrapper>
							</Styled.CardContent>
							<Styled.CardFooter>
								<Styled.CardFooterAction transparent onClick={handleCancelStake}>
									Cancel
								</Styled.CardFooterAction>
								<Styled.CardFooterAction onClick={handleStake}> Stake </Styled.CardFooterAction>
							</Styled.CardFooter>
						</Styled.Card>
					</Styled.DialogWindow>
					<Styled.DialogBackdrop onClick={handleCancelStake} />
				</Styled.Dialog>
			)}
		</Container>
	)
}

LPPage.propTypes = {}

LPPage.defaultProps = {}

export default LPPage
