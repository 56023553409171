import React, { useRef } from 'react'
import * as Styled from './SearchInput.styles'

interface SearchInputProps {
	value: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInput = (props: SearchInputProps) => {
	const inputRef = useRef(null)
	return (
		<Styled.InputWrapper>
			<Styled.SearchIcon />
			<Styled.Input {...props} ref={inputRef} placeholder="NFT ID..." />
		</Styled.InputWrapper>
	)
}

export default SearchInput
