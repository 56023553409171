import { Button as BsButton, FormControl } from 'react-bootstrap'
import styled from 'styled-components'

export const Title = styled.h1`
	margin-bottom: 24px;
	font-family: 'Roboto Mono', monospace;
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	line-height: 84px;
	color: #000000;

	&.h3 {
		font-size: 32px;
		line-height: 42px;
	}
`

export const Paragraph = styled.p`
	margin-bottom: 16px;
	font-family: 'Roboto Mono', monospace;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
`

export const Section = styled.section`
	padding-top: 56px;
	padding-bottom: 56px;
`

export const Button = styled(BsButton)`
	padding: 16px 24px;
	color: #fff;
	font-family: 'Roboto Mono', monospace;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	background-color: #000;
	border-radius: 2px;
	border: 0;

	&:hover,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		background-color: #464646;
	}

	&:focus,
	&:not(:disabled):not(.disabled).active:focus,
	&:not(:disabled):not(.disabled):active:focus {
		background-color: #000;
		box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5);
	}
`

export const Input = styled(FormControl)`
	height: initial;
	padding: 16px 24px;
	margin-bottom: 16px;
	border: 1px solid #000;
	font-family: 'Roboto Mono', monospace;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	border-radius: 0;

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5);
	}
`

export const Card = styled.div`
	padding: 40px;
	border: 2px solid #000;
`
