import React, { useRef } from 'react'
import * as Styled from './SearchInput.styles'

const SearchInput = (props) => {
	const inputRef = useRef(null)
	return (
		<Styled.InputWrapper>
			<Styled.SearchIcon />
			<Styled.Input {...props} ref={inputRef} placeholder="NFT ID..." />
		</Styled.InputWrapper>
	)
}

SearchInput.propTypes = {}

SearchInput.defaultProps = {}

export default SearchInput
